import {
  FinancialProfile,
  Organization,
  Sector,
  User,
} from "@/modules/Auth/types";
import { NOTIFICATION_MESSAGES } from "../constants/notificationMessages";
import { STORAGE_KEYS } from "../constants/storageKeys";
import { NOTIFICATION_TYPES } from "../enums/NOTIFICATION_TYPES";
import {
  NOTIFICATION_EVENTS,
  notificationBus,
} from "../eventBusses/notification";
import { INotification } from "../interfaces/notification.interface";
import storageService from "../services/storage.service";
import { getThemeColor, setThemeColor } from "./colortheme";
import store from "@/store";
import { AUTH_MUTATIONS } from "@/modules/Auth/vuex/mutations";
import { PERMISSIONS } from "../enums/PERMISSIONS";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { USER_ROLES } from "../enums/USER_ROLES";
import {
  BaggageInfo,
  DealDiscount,
  DiscountType,
  FareOption,
} from "@/ag-flight-components/types";
import { getGrossFare } from "@/ag-flight-components/utils";
import {
  AmountType,
  FareType,
  TemperFare,
} from "@/modules/FlightSearchResult/types";
import { PATH } from "../constants/path";
import { useIsMobile } from "@aeroglobe/ag-core-ui";

const UTILS = {
  getThemeColor,
  setThemeColor,

  notify(description: string | null, type = NOTIFICATION_TYPES.INFO): void {
    const data: INotification = {
      type: type,
      description: description || NOTIFICATION_MESSAGES.DEFAULT,
    };

    notificationBus.emit(NOTIFICATION_EVENTS.TRIGGER, data);
  },

  updateUser(user: User) {
    storageService.setItem(STORAGE_KEYS.USER, JSON.stringify(user));

    const organization = user.agent_user_data.organization;

    setThemeColor(organization);

    this.updateOrganization(organization);

    store.commit(AUTH_MUTATIONS.UPDATE_USER, user);
  },

  updateAuthTokens(access: string, refresh: string) {
    storageService.setItem(STORAGE_KEYS.ACCESS_TOKEN, access, false);
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh, false);

    store.commit(AUTH_MUTATIONS.UPDATE_AUTH_TOKENS, { access, refresh });
  },

  updatePermissions(permissions: PERMISSIONS[]) {
    storageService.setItem(
      STORAGE_KEYS.PERMISSIONS,
      JSON.stringify(permissions)
    );

    store.commit(AUTH_MUTATIONS.PERMISSIONS, permissions);
  },

  updateOrganization(organization?: Organization) {
    storageService.setItem(
      STORAGE_KEYS.ORGANIZATION,
      JSON.stringify(organization)
    );

    if (organization) {
      storageService.setItem(
        STORAGE_KEYS.FINANCIAL_PROFILES,
        JSON.stringify(organization.financial_profiles)
      );
    } else {
      storageService.removeItem(STORAGE_KEYS.ORGANIZATION);
      storageService.removeItem(STORAGE_KEYS.FINANCIAL_PROFILES);
    }

    store.commit(AUTH_MUTATIONS.UPDATE_ORGANIZATION, organization);
  },

  updateFinancialProfiles(financialProfiles: FinancialProfile[]) {
    storageService.setItem(
      STORAGE_KEYS.FINANCIAL_PROFILES,
      JSON.stringify(financialProfiles)
    );

    store.commit(AUTH_MUTATIONS.UPDATE_FINANCIAL_PROFILES, financialProfiles);
  },

  updateSectors(sectors: Sector[]) {
    storageService.setItem(STORAGE_KEYS.SECTORS, JSON.stringify(sectors));

    store.commit(AUTH_MUTATIONS.UPDATE_SECTORS, sectors);
  },

  getCurrency() {
    const currency = store.getters[AUTH_GETTERS.CURRENCY] as string | null;

    return currency;
  },

  isUser(...roles: USER_ROLES[]) {
    const user = store.getters[AUTH_GETTERS.USER_ROLE] as USER_ROLES | null;

    return user ? roles.includes(user) : false;
  },

  mapSectorInFinancialProfiles(
    sector: string,
    financialProfiles: FinancialProfile[]
  ): FinancialProfile[] {
    let tempFp = financialProfiles;

    tempFp = tempFp.map((fp) => {
      return { ...fp, sector };
    });

    return tempFp;
  },

  parseDiscountLabel(data: DealDiscount | null): string {
    if (!data) {
      return "";
    }

    const {
      discount_type,
      discount_on_gross_fare,
      discount_amount,
      discount_currency,
    } = data;

    const amount =
      discount_type === DiscountType.PERCENTAGE
        ? `${discount_amount}%`
        : `${discount_amount}${discount_currency}`;

    const fareType = discount_on_gross_fare ? "Gross Fare" : "Base Fare";
    return `Applied on ${fareType} with ${amount} discount`;
  },

  getBaggageInfo(baggage_info?: BaggageInfo | null): string {
    if (!baggage_info) {
      return "No Baggage";
    }

    const { pieces = 0, weight = 0, unit = "" } = baggage_info;

    if (pieces > 0) {
      if (weight > 0) {
        return `Total ${weight.toFixed(1)} ${unit} (${pieces} piece(s))`;
      } else {
        return `As per airline policy (${pieces} piece(s))`;
      }
    } else if (weight > 0) {
      return `${weight.toFixed(1)} ${unit} (As per airline policy)`;
    } else {
      return "No Baggage";
    }
  },

  getFormattedCurrency(amount: number) {
    const currency = this.getCurrency();

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency ?? "PKR",
      minimumFractionDigits: 0,
    }).format(Number(amount));
  },

  calculateFlightPrice(fareOption: FareOption, temperFare: TemperFare): number {
    const fareCalculationAmount = temperFare.amount;
    const fareType = temperFare.fareType;
    const amountType = temperFare.amountType;

    if (!fareCalculationAmount) {
      return getGrossFare(fareOption);
    }

    let fare = getGrossFare(fareOption);
    let amountToAdd = fareCalculationAmount;
    let tax = 0;

    if (fareType === FareType.BASE) {
      fare = Number(fareOption.price.base_fare.value);
      tax = Number(fareOption.price.tax.value);
    }

    if (amountType === AmountType.PERCENTAGE) {
      amountToAdd = (fare / 100) * fareCalculationAmount;
    }

    return fare + amountToAdd + tax;
  },

  flightSearchResultsPath(): string {
    return PATH.FLIGHTS_SEARCH_RESULT;
  },

  toTitleCase(input: string): string {
    const convertedInput = input
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return convertedInput;
  },
};

export default UTILS;
