import RestClientService from "@/ag-portal-common/services/restClient.service";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { NOTIFICATION_MESSAGES } from "@/ag-portal-common/constants/notificationMessages";
import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import { MY_ISSUANCE_API_PATH } from "@/modules/MyIssuances/constants";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import { CACHE_NAMES } from "@/ag-portal-common/constants";
import { IMyFlightIssuance } from "@/ag-portal-common/interfaces/myIssuance.interface";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";

class MyIssuanceService {
  private restClient: RestClientService;

  constructor() {
    this.restClient = new RestClientService();
  }

  public async fetchMyIssuance(
    payload: FetchBookingsParamsRequestModel,
    isFetchNewData: boolean
  ): Promise<IMyFlightIssuance> {
    const methodName = "MyIssuanceService.fetchMyIssuance";
    let mappedFlightBookings: IMyFlightIssuance = {
      count: 0,
      next: "",
      previous: "",
      bookings: [],
    };
    try {
      if (isFetchNewData) {
        await caches.delete(CACHE_NAMES.flightsBookings);
      }
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const response = await this.restClient.get(
        MY_ISSUANCE_API_PATH.GET_ISSUANCE_LIST,
        payload
      );
      if (response.success && response.data) {
        mappedFlightBookings = response.data;
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description =
          response.message || response.error || NOTIFICATION_MESSAGES.DEFAULT;
        notificationService.triggerNotification();
      }
    } catch (err) {
      loggerService.logError(`${methodName}: ${LOG_LABELS.ERROR}`, err);
    }
    return mappedFlightBookings;
  }
  public getMyIssuanceDetail(
    issuanceId: string,
    organizationId: string
  ): Promise<IAGResponse> {
    return this.restClient.get(
      MY_ISSUANCE_API_PATH.GET_ISSUANCE_DETAILS +
        issuanceId +
        "/" +
        "?organization=" +
        organizationId
    );
  }
}

export default MyIssuanceService;
