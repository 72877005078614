<template>
  <MAccordion :initial-open="true">
    <template v-slot:title>
      <AgIconInfoBar
        test-id=""
        title="Flight Details"
        class="section-title"
        mPrepandIcon="m-flight"
      />
    </template>
    <template v-slot:content>
      <MDataTable
        :headers="tableHeaders"
        :data="flightSegment"
        :item-per-page="30"
        :is-api-paginated="false"
        :has-search="false"
        :has-pagination="false"
      >
        <template #baggage_info="{ item }">
          <MTypography type="label">{{
            getBaggageInfo(item.baggage_info)
          }}</MTypography>
        </template>
        <template #rbd="{ item }">
          <MTypography type="label">
            {{ item.rbd ?? "-" }}
          </MTypography>
        </template>
        <template #departure_date="{ item }">
          <MTypography class="description td-full-width" type="body">
            {{
              getFormattedDateTime(
                item.departure_date,
                FORMAT_DD_MMM_YYYY_WITH_DASH
              )
            }}
          </MTypography>
          <MTypography class="description" type="label">
            {{
              getFormattedDateTime(item.departure_date, FORMAT_HH_SS_24_HOURS)
            }}
          </MTypography>
        </template>

        <template #arrival_date="{ item }">
          <MTypography class="description td-full-width" type="body">
            {{
              getFormattedDateTime(
                item.arrival_date,
                FORMAT_DD_MMM_YYYY_WITH_DASH
              )
            }}
          </MTypography>
          <MTypography class="description" type="label">
            {{ getFormattedDateTime(item.arrival_date, FORMAT_HH_SS_24_HOURS) }}
          </MTypography>
        </template>
        <template #airline="{ item }">
          <MTypography class="description td-full-width" type="label">
            {{ getAirlineInfo(item.airline).name }}
          </MTypography>
        </template>
      </MDataTable>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MAccordion, MTypography } from "@aeroglobe/ag-core-ui";
import {
  FlightDetails,
  FlightSegment,
  IssuanceDetails,
  Passenger,
} from "@/ag-portal-common/interfaces/myIssuance.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";

import { getFormattedDateTime } from "@/ag-portal-common/utils/helpers";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { Airline, BaggageInfo } from "@/ag-flight-components/types";
import UTILS from "@/ag-portal-common/utils";
export default defineComponent({
  name: "IssuancePassengerDetails",
  components: {
    MAccordion,
  },
  data() {
    return {
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      tableHeaders: [
        {
          title: "Airline",
          value: "airline",
          key: "airline",
          sortable: true,
        },
        {
          title: "Flight Number",
          value: "flight_number",
          key: "flight_number",
          sortable: true,
        },
        {
          title: "Origin",
          value: "origin",
          key: "origin",
          sortable: true,
        },
        {
          title: "Destination",
          value: "destination",
          key: "destination",
          sortable: true,
        },
        {
          title: "Departure Date",
          value: "departure_date",
          key: "departure_date",
          sortable: true,
        },
        {
          title: "Arrival Date",
          value: "arrival_date",
          key: "arrival_date",
          sortable: true,
        },
        {
          title: "RBD",
          value: "rbd",
          key: "rbd",
          sortable: true,
        },
        {
          title: "Baggage Info",
          value: "baggage_info",
          key: "baggage_info",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  methods: {
    getFormattedValue(value: string | number): string {
      return value ? value.toString().replace(/_/g, " ") : "-";
    },
    getBaggageInfo(baggageInfo?: BaggageInfo | null): string {
      return UTILS.getBaggageInfo(baggageInfo);
    },
    getAirlineInfo(airlineInfo?: Airline | null): {
      name: string;
      logo: string | null;
    } {
      if (airlineInfo) {
        return {
          name: this.getFormattedValue(airlineInfo.name).toUpperCase(),
          logo: airlineInfo.logo,
        };
      }
      return {
        name: "-",
        logo: null,
      };
    },
    getFormattedDateTime,
  },
  computed: {
    flightDetails(): FlightDetails {
      const myFlightIssuanceDetails = this.myFlightIssuanceDetails;
      return myFlightIssuanceDetails.flight_details;
    },
    flightSegment(): FlightSegment | null {
      const flightDetails = this.flightDetails;
      return flightDetails.flight_segments || null;
    },
    myFlightIssuanceDetails(): IssuanceDetails {
      return this.$store.getters.myFlightIssuanceDetails;
    },
  },
});
</script>

<style scoped lang="css">
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
