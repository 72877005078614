<template>
  <MDialog
    persistent
    v-model="showCancellation"
    @close="handleCloseIssuanceDialog"
    title="Hotel Issuance"
  >
    <template #content>
      <CancellationDialogBody v-if="showCancellation" />
    </template>
    <template #actions>
      <MButton @click="handleCloseIssuanceDialog" type="outlined">
        Cancel
      </MButton>
      <MButton @click="handleContinue"> Continue </MButton>
    </template>
  </MDialog>
  <OTPInputModal
    :isOpen="showOtp"
    @close="showOtp = false"
    @submit="onHotelIssuance"
    :isSubmitLoading="isLoadingOTPVerificationSubmit"
    title="OTP Confirmation"
    instruction="Enter the 6-digit code sent to your email."
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CancellationDialogBody from "../components/HotelIssuanceDialog/CancellationDialogBody.vue";
import OTPInputModal from "@/ag-flight-components/components/OTPModal.vue";
export default defineComponent({
  name: "HotelIssuanceDialog",
  components: {
    CancellationDialogBody,
    OTPInputModal,
  },
  data() {
    return {
      showCancellation: this.isOpen,
      showOtp: false,
      isLoadingOTPVerificationSubmit: false,
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    handleCloseDialog: {
      type: Function,
      required: true,
    },
  },
  watch: {
    isOpen(newVal) {
      this.showCancellation = newVal;
    },
  },
  methods: {
    showOtpModal(): boolean {
      return this.showOtp;
    },
    toggleOtpModal() {
      this.showOtp = !this.showOtp;
      return this.showOtp;
    },
    handleContinue() {
      this.showCancellation = false;
      this.showOtp = true;
    },
    onHotelIssuance(otp: string) {
      const booking_id = this.$route.params?.id;
      this.isLoadingOTPVerificationSubmit = true;
      this.$store.dispatch("issueHotelBooking", {
        payload: { booking_id, otp },
        successCallback: () => {
          this.showOtp = false;
        },
      });
    },
    handleCloseIssuanceDialog() {
      this.showCancellation = false;
      this.handleCloseDialog();
    },
  },
  beforeUnmount() {
    this.showCancellation = false;
  },
});
</script>
