<template>
  <MProgress
    indeterminate
    v-if="isMyIssuanceDetailsLoading"
    class="mprogress-alignment"
  />
  <div v-else-if="myFlightIssuanceDetails">
    <IssuanceBasicDetails />
    <IssuanceFlightDetails />
    <IssuancePassengerDetails />
    <IssuancePassengerWiseFareDetails />
  </div>
</template>

<script lang="ts">
import { IssuanceDetails } from "@/ag-portal-common/interfaces/myIssuance.interface";
import { MTypography } from "@aeroglobe/ag-core-ui";
import IssuanceBasicDetails from "@/modules/MyIssuances/components/IssuanceBasicDetails.vue";
import IssuancePassengerDetails from "@/modules/MyIssuances/components/IssuancePassengerDetails.vue";
import IssuanceFlightDetails from "@/modules/MyIssuances/components/IssuanceFlightDetails.vue";
import IssuancePassengerWiseFareDetails from "@/modules/MyIssuances/components/IssuancePassengerWiseFareDetails.vue";
import { defineComponent } from "vue";
import UTILS from "@/ag-portal-common/utils";
import { Organization, USER_ROLES } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "MyIssuanceDetails",
  components: {
    IssuanceBasicDetails,
    IssuancePassengerDetails,
    IssuancePassengerWiseFareDetails,
    IssuanceFlightDetails,
  },
  async created() {
    const issuanceId = this.renderIssaunceId;
    const isAgentUser = this.isAgentUser;
    let organizationId = "";
    if (isAgentUser) {
      organizationId = this.getOrganizationId;
    } else {
      organizationId = this.renderOrganizationId;
    }
    this.$store.dispatch("fetchMyIssuanceDetails", {
      issuanceId: issuanceId,
      organizationId: organizationId,
    });
  },
  computed: {
    myFlightIssuanceDetails(): IssuanceDetails {
      return this.$store.getters.myFlightIssuanceDetails;
    },
    isMyIssuanceDetailsLoading(): boolean {
      return this.$store.getters.isMyIssuanceDetailsLoading;
    },
    renderIssaunceId(): string {
      const issuanceId = this.$route.params?.id as string;
      return issuanceId;
    },
    renderOrganizationId(): string {
      const organizationId = this.$route.params?.organizationId as string;
      return organizationId;
    },
    renderBookingStatus(): string {
      const details = this.myFlightIssuanceDetails;
      return details.status;
    },
    isAgentUser(): boolean {
      return UTILS.isUser(
        USER_ROLES.AGENT,
        USER_ROLES.EMPLOYEE,
        USER_ROLES.SUB_AGENT
      );
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    getOrganizationId(): string {
      const organizationId = this.organization
        ? this.organization.organization_id
        : "";
      return organizationId;
    },
  },
});
</script>
