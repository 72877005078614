import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, { "initial-open": true }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        title: "Passenger Wise Fare Details",
        class: "section-title",
        mPrepandIcon: "m-payment-management"
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_MDataTable, {
        headers: _ctx.tableHeaders,
        data: _ctx.amountBreakDown,
        "item-per-page": 3,
        "is-api-paginated": false,
        "has-search": false,
        "has-pagination": false
      }, {
        base_fare: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.base_fare.currency, item.base_fare.value)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        total_fare: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.total_fare.currency, item.total_fare.value)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        ag_fare: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formattedCurrency(item.ag_fare.currency, item.ag_fare.value)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        deal_discount: _withCtx(({ item }) => [
          (item.deal_discount)
            ? (_openBlock(), _createBlock(_component_MTypography, {
                key: 0,
                type: "label"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formattedCurrency(
              item.deal_discount.currency,
              item.deal_discount.value
            )), 1)
                ]),
                _: 2
              }, 1024))
            : (_openBlock(), _createBlock(_component_MTypography, {
                key: 1,
                type: "label"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" - ")
                ]),
                _: 1
              }))
        ]),
        _: 1
      }, 8, ["headers", "data"])
    ]),
    _: 1
  }))
}