import loggerService from "@/ag-portal-common/services/logger.service";
import { LOG_LABELS } from "@/ag-portal-common/constants/logLabels";
import MyIssuanceServices from "@/modules/MyIssuances/services/myIssuance.service";
import { ActionContext, ActionTree, Commit } from "vuex";
import {
  IMyFlightIssuance,
  IMyIssuanceState,
} from "@/ag-portal-common/interfaces/myIssuance.interface";
import { StoreState } from "@/store/type";
import { FetchBookingsParamsRequestModel } from "@/modules/MyBookings/models/fetchBookingsParams.request";
import notificationService from "@/ag-portal-common/services/notification.service";
import { NOTIFICATION_TYPES } from "@/ag-portal-common/enums/NOTIFICATION_TYPES";
import { IAGResponse } from "@/ag-portal-common/interfaces/agResponse.interface";
import { StatusCodes } from "http-status-codes";

const actions: ActionTree<IMyIssuanceState, StoreState> = {
  async fetchMyIssuance(
    context: ActionContext<IMyIssuanceState, StoreState>,
    {
      payload,
      isFetchNewData,
      callBack,
    }: {
      payload: FetchBookingsParamsRequestModel;
      isFetchNewData: boolean;
      callBack?: () => void;
    }
  ) {
    const methodName = "actions.fetchMyIssuance";
    context.commit("setMyIssuanceListLoading", true);
    context.commit("resetMyIssuanceList");
    loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);

    try {
      const myIssuanceServices = new MyIssuanceServices();

      const response: IMyFlightIssuance =
        await myIssuanceServices.fetchMyIssuance(payload, isFetchNewData);
      context.commit("setMyIssuanceList", response);

      if (callBack) {
        callBack();
      }

      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ENDED}`);
    } catch (error) {
      context.commit("setMyIssuanceListLoading", false);
    } finally {
      context.commit("setMyIssuanceListLoading", false);
    }
  },
  async fetchMyIssuanceDetails(
    context: ActionContext<IMyIssuanceState, StoreState>,
    {
      issuanceId,
      organizationId,
    }: { issuanceId: string; organizationId: string }
  ) {
    const methodName = "actions.fetchMyIssuanceDetails";
    context.commit("resetIssuanceDetails");
    try {
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.INITIATED}`);
      const myIssuanceServices = new MyIssuanceServices();
      context.commit("setMyIssuanceDetailsLoading", true);
      const response: IAGResponse =
        await myIssuanceServices.getMyIssuanceDetail(
          issuanceId,
          organizationId
        );
      if (response.success && response.status === StatusCodes.OK) {
        context.commit("setMyIssuanceDetails", response.data.data);
        context.commit("setMyIssuanceDetailsLoading", false);
      } else {
        notificationService.type = NOTIFICATION_TYPES.ERROR;
        notificationService.description = response.message || "";
        notificationService.triggerNotification();
      }
    } catch (err) {
      notificationService.type = NOTIFICATION_TYPES.ERROR;
      notificationService.description =
        "Error while trying to fetch my issuance details ";
      notificationService.triggerNotification();
      loggerService.logInfo(`${methodName}: ${LOG_LABELS.ERROR}`, err);
      context.commit("setMyIssuanceDetailsLoading", false);
    } finally {
      context.commit("setMyIssuanceDetailsLoading", false);
    }
  },
};

export default actions;
