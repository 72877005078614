import { GetterTree } from "vuex";
import { IMyIssuanceState } from "@/ag-portal-common/interfaces/myIssuance.interface";
import { StoreState } from "@/store/type";

const getters: GetterTree<IMyIssuanceState, StoreState> = {
  isMyIssuanceListLoading: (state) => state.isMyFlightIssuanceLoading,
  myIssuanceList: (state) => state.myFlightIssuanceList,
  isMyIssuanceDetailsLoading: (state) => state.isMyIssuanceDetailsLoading,
  myFlightIssuanceDetails: (state) => state.myFlightIssuanceDetails,
};

export default getters;
