<template>
  <MProgress
    indeterminate
    v-if="isIssuanceListLoading && isFirstPage"
    class="mprogress-alignment"
  />
  <AgTabs test-id="test" v-else>
    <template #TabHeading>
      <v-tab value="one">
        <MIcon name="m-flight" />
        <span>Flights</span>
      </v-tab>
    </template>
    <template #TabDetail>
      <v-window-item
        value="one"
        transition="fade"
        reverse-transition="fade"
        :disabled="true"
      >
        <MDataTable
          :is-api-paginated="true"
          :total-item-count="totalPageCount"
          :item-per-page="30"
          :headers="tableHeaders"
          :is-loading="$store.getters.isMyIssuanceListLoading"
          :data="myIssuanceList || []"
          :has-search="true"
          @onPageChange="onPageChange"
        >
          <template #issuanceId="{ item }">
            <router-link
              :to="
                formatStringToRoutePath(PATH.FLIGHTS_ISSUANCE_DETAILS, {
                  id: item.issuance_id,
                  organizationId: item.agent.organization_id,
                })
              "
              v-if="isLinkActive(item.status)"
            >
              <MTypography type="body" class="issuance-id">
                {{ item.issuance_id }}
              </MTypography>
            </router-link>

            <MTypography type="body" class="issuance-id" v-else>
              {{ item.issuance_id }}
            </MTypography>
          </template>
          <template #issued_at="{ item }">
            <div v-if="item.issuance_time">
              <MTypography class="description" type="body">
                {{
                  getFormattedDateTime(
                    item.issuance_time,
                    FORMAT_DD_MMM_YYYY_WITH_DASH
                  )
                }}
              </MTypography>
              <MTypography class="description" type="label" issuance_time>
                {{
                  getFormattedDateTime(
                    item.issuance_time,
                    FORMAT_HH_SS_24_HOURS
                  )
                }}
              </MTypography>
            </div>
            <MTypography v-else>{{
              sanitizeItem(item.issuance_time)
            }}</MTypography>
          </template>
          <template #booking_type="{ item }">
            <MChip
              :variant="renderBookingType(item.booking_type)"
              class="m-chip-issuance-list"
            >
              {{ sanitizeItem(item.booking_type) }}</MChip
            >
          </template>
          <template #status="{ item }">
            <MChip
              :variant="renderStatusSeverity(item.status)"
              class="m-chip-issuance-list"
            >
              {{ sanitizeItem(item.status) }}</MChip
            >
          </template>
          <template #supplier="{ item }">
            <MTypography type="label">
              {{ renderSupplier(item.flight_details) }}
            </MTypography>
          </template>
          <template #flight_sector="{ item }">
            <MTypography type="label">
              {{ renderSector(item.flight_details) }}
            </MTypography>
          </template>
          <template #pnr="{ item }">
            <MTypography type="label">
              {{ sanitizeItem(renderPnr(item.flight_details)) }}
            </MTypography>
          </template>
          <template #agent="{ item }">
            <MTypography type="label">
              {{ sanitizeItem(renderAgentEmail(item.agent)) }}
            </MTypography>
          </template>

          <template #travelers="{ item }">
            <div v-if="!hasTravelers(item.flight_details)">-</div>
            <AGButtonDropDown
              v-else
              class="table_dropdown_wrap"
              text="Passengers"
              :items="item.flight_details.passengers"
            >
              <template #label>
                <div>
                  <MTypography class="description td-full-width" type="label">
                    {{ formatTravelerName(item.flight_details.passengers[0]) }}
                  </MTypography>
                </div>
              </template>

              <AGDropDownButtonItem
                class="dropdown-width"
                v-for="(passenger, index) in item.flight_details.passengers"
                :key="index"
                :name="formatTravelerName(passenger)"
              />
            </AGButtonDropDown>
          </template>
        </MDataTable>
      </v-window-item>
    </template>
  </AgTabs>
</template>

<script lang="ts">
import {
  genTestId,
  getFormattedDateTime,
} from "@/ag-portal-common/utils/helpers";
import { defineComponent } from "vue";
import { MDataTable, MTypography } from "@aeroglobe/ag-core-ui";
import { MDataTableHeader } from "@/ag-portal-common/types";
import {
  FORMAT_DD_MMM_YYYY_WITH_DASH,
  FORMAT_HH_SS_24_HOURS,
} from "@/ag-portal-common/constants/dateTimeFormats";
import { formatStringToRoutePath } from "@/ag-portal-common/utils/helpers";
import { PATH } from "@/ag-portal-common/constants/path";
import {
  AgentDetails,
  FlightDetails,
  FlightSegment,
  ISSUANCE_BOOKING_TYPE,
  ISSUANCE_STATUS,
  IssuanceDetails,
} from "@/ag-portal-common/interfaces/myIssuance.interface";
import { Organization, USER_ROLES } from "@/modules/Auth/types";
import UTILS from "@/ag-portal-common/utils";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";

export default defineComponent({
  name: "MyIssuanceList",
  data() {
    return {
      itemsPerPage: 0,
      fetchBookingParams: {
        page: 1,
        start: null,
        end: null,
        search: "",
        organization: "",
        action_type: "selected",
      },
      FORMAT_DD_MMM_YYYY_WITH_DASH: FORMAT_DD_MMM_YYYY_WITH_DASH,
      FORMAT_HH_SS_24_HOURS: FORMAT_HH_SS_24_HOURS,
      tableHeaders: [
        {
          title: "Issuance ID",
          value: "issuanceId",
          key: "issuanceId",
          sortable: true,
        },
        {
          title: "PNR",
          value: "pnr",
          key: "pnr",
          sortable: true,
        },
        {
          title: "Sector",
          value: "flight_sector",
          key: "flight_sector",
          sortable: true,
        },
        {
          title: "Supplier",
          value: "supplier",
          key: "supplier",
          sortable: true,
        },
        {
          title: "Travelers",
          value: "travelers",
          key: "travelers",
          sortable: true,
        },
        {
          title: "Status",
          value: "status",
          key: "status",
          sortable: true,
        },
        {
          title: "Booking Type",
          value: "booking_type",
          key: "booking_type",
          sortable: true,
        },
        {
          title: "Issued At",
          value: "issued_at",
          key: "issued_at",
          sortable: true,
        },
        {
          title: "Agent",
          value: "agent",
          key: "agent",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  async created() {
    const isNewFetchData = false;
    this.fetchIssuances(isNewFetchData, this.setItemsPerPage);
  },
  computed: {
    PATH(): typeof PATH {
      return PATH;
    },
    isFirstPage(): boolean {
      return this.fetchBookingParams.page === 1;
    },
    isIssuanceListLoading(): boolean {
      return this.$store.getters.isMyIssuanceListLoading;
    },
    totalPageCount(): number {
      return this.$store.getters.myIssuanceList.count;
    },
    myIssuanceList(): IssuanceDetails[] {
      return this.$store.getters.myIssuanceList.results;
    },
    isAgentUser(): boolean {
      return UTILS.isUser(
        USER_ROLES.AGENT,
        USER_ROLES.EMPLOYEE,
        USER_ROLES.SUB_AGENT
      );
    },
    organization(): Organization | null {
      return this.$store.getters[AUTH_GETTERS.ORGANIZATION];
    },
    getOrganizationId(): string {
      const organizationId = this.organization
        ? this.organization.organization_id
        : "";
      return organizationId;
    },
  },
  methods: {
    genTestId,
    getFormattedDateTime,
    formatStringToRoutePath,
    isLinkActive(status: ISSUANCE_STATUS) {
      if (status === ISSUANCE_STATUS.ISSUANCE_SUCCESS) {
        return true;
      } else {
        return false;
      }
    },
    renderSupplier(flightDetails: FlightDetails) {
      return flightDetails.supplier.toUpperCase();
    },
    renderSector(flightDetails: FlightDetails) {
      return flightDetails.flight_sector;
    },
    renderPnr(flightDetails: FlightDetails) {
      return flightDetails.pnr;
    },
    renderAgentEmail(agentDetails: AgentDetails) {
      return agentDetails.email;
    },
    renderStatusSeverity(status: ISSUANCE_STATUS) {
      switch (status) {
        case ISSUANCE_STATUS.ISSUANCE_SUCCESS:
          return "success";
        case ISSUANCE_STATUS.FETCHED_FAILURE:
          return "error";
        case ISSUANCE_STATUS.ISSUANCE_FAILURE:
          return "error";
        case ISSUANCE_STATUS.FETCHED_SUCCESS:
          return "success";
        case ISSUANCE_STATUS.PRE_ISSUANCE_FAILURE:
          return "error";
        case ISSUANCE_STATUS.POST_ISSUANCE_FAILURE:
          return "error";
        default:
          return "info";
      }
    },
    renderBookingType(status: ISSUANCE_BOOKING_TYPE) {
      switch (status) {
        case ISSUANCE_BOOKING_TYPE.PORTAL_BOOKING:
          return "success";
        case ISSUANCE_BOOKING_TYPE.NON_PORTAL_BOOKING:
          return "info";
        default:
          return "info";
      }
    },
    sanitizeItem(item: string) {
      return item?.replaceAll("_", " ") ?? "-";
    },
    formatTravelerName(passenger: string) {
      if (!passenger) return "-";
      return passenger;
    },
    hasTravelers(flight_details: FlightDetails) {
      const travelers = flight_details.passengers;
      return travelers && travelers.length > 0;
    },
    getDepartureDate(flightSegment: FlightSegment): Date {
      if (flightSegment && flightSegment.departure_date) {
        return new Date(flightSegment.departure_date);
      } else {
        return new Date();
      }
    },
    onPageChange(num: number) {
      let nextPage = this.$store.getters.myIssuanceList.next;
      let prevPage = this.$store.getters.myIssuanceList.previous;
      let currentPage: number = this.fetchBookingParams.page || 1;
      if ((num > currentPage && nextPage) || (currentPage > num && prevPage)) {
        this.fetchBookingParams.page = num;
        const isNewFetchData = true;
        this.fetchIssuances(isNewFetchData);
      }
    },
    setItemsPerPage() {
      let bookingsLength = this.$store.getters.myIssuanceList.bookings.length;
      this.itemsPerPage = bookingsLength;
    },
    fetchIssuances(isFetchNewData = false, callBack?: () => void) {
      const payload = this.fetchBookingParams;
      const isAgentUser = this.isAgentUser;
      if (isAgentUser) {
        this.fetchBookingParams.organization = this.getOrganizationId;
      } else {
        this.fetchBookingParams.action_type = "all";
      }
      this.$store.dispatch("fetchMyIssuance", {
        payload,
        isFetchNewData,
        callBack,
      });
    },
  },
});
</script>

<style lang="css" scoped>
.issuance-id {
  color: var(--green-color);
}
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}

.m-chip-issuance-list {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: max-content;
  margin: 0 auto;
}
</style>
