import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-91d7ddbc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flight-refunds-content" }
const _hoisted_2 = { class: "refund-passenger" }
const _hoisted_3 = { class: "widget_border refunds-checkbox" }
const _hoisted_4 = { class: "m-flight" }
const _hoisted_5 = { class: "m-title" }
const _hoisted_6 = { class: "m-icon" }
const _hoisted_7 = { class: "m-body" }
const _hoisted_8 = { class: "m-timeline" }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = { class: "m-icon" }
const _hoisted_11 = { class: "date" }
const _hoisted_12 = { class: "m-icon" }
const _hoisted_13 = { class: "date" }
const _hoisted_14 = { class: "m-icon" }
const _hoisted_15 = { class: "date" }
const _hoisted_16 = { class: "m-icon" }
const _hoisted_17 = { class: "date" }
const _hoisted_18 = { class: "m-icon" }
const _hoisted_19 = { class: "date" }
const _hoisted_20 = { class: "m-icon" }
const _hoisted_21 = { class: "date" }
const _hoisted_22 = { class: "m-icon" }
const _hoisted_23 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_RefundStatusFlag = _resolveComponent("RefundStatusFlag")!
  const _component_MCard = _resolveComponent("MCard")!
  const _component_AgContactInfo = _resolveComponent("AgContactInfo")!
  const _component_m_flight_take_off_icon = _resolveComponent("m-flight-take-off-icon")!
  const _component_m_typography = _resolveComponent("m-typography")!
  const _component_m_flight_timeline_icon = _resolveComponent("m-flight-timeline-icon")!
  const _component_MAccordion = _resolveComponent("MAccordion")!
  const _component_MButton = _resolveComponent("MButton")!

  return (_openBlock(), _createBlock(_component_MAccordion, {
    "initial-open": true,
    id: "RefundSummary"
  }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        mPrepandIcon: "m-passenger-management",
        title: "Refund Summary",
        class: "section-title"
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_MCard, { class: "no-padding-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_RefundStatusFlag, {
            title: "Total Amount",
            code: _ctx.getCalculatedSummary().total_amount,
            class: "refund-summary-flag"
          }, null, 8, ["code"]),
          _createVNode(_component_RefundStatusFlag, {
            title: "Total Refund Charges",
            code: _ctx.getCalculatedSummary().total_refund_charges,
            class: "refund-summary-flag"
          }, null, 8, ["code"]),
          _createVNode(_component_RefundStatusFlag, {
            title: "Total Refundable Amount",
            code: _ctx.getCalculatedSummary().refundable_amount,
            class: "refund-summary-flag"
          }, null, 8, ["code"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPassengers, (passenger, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              _createVNode(_component_MAccordion, {
                class: "passenger-accordion-header",
                "initial-open": true
              }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_AgContactInfo, {
                        "icon-name": "pakFlag",
                        title: 
                        _ctx.renderPassengerTitle(
                          passenger.first_name,
                          passenger.last_name,
                          passenger.title
                        )
                      ,
                        class: "contact-info"
                      }, null, 8, ["title"]),
                      _createVNode(_component_AgContactInfo, {
                        mPrepandIcon: 
                        _ctx.renderPassengerType(passenger.passenger_type).icon
                      ,
                        title: 
                        _ctx.renderPassengerType(passenger.passenger_type).type
                      ,
                        class: "contact-info"
                      }, null, 8, ["mPrepandIcon", "title"]),
                      _createVNode(_component_AgContactInfo, {
                        "icon-name": "notInvoice",
                        title: passenger.ticket_number,
                        class: "contact-info"
                      }, null, 8, ["title"])
                    ])
                  ])
                ]),
                content: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(passenger.leg_details, (legs, legIndex) => {
                      return (_openBlock(), _createBlock(_component_MCard, {
                        key: legIndex,
                        class: "no-padding-card"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              _createElementVNode("span", _hoisted_6, [
                                _createVNode(_component_m_flight_take_off_icon)
                              ]),
                              _createVNode(_component_m_typography, {
                                type: "body",
                                class: "m-text"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.renderSegmentNames(legs)), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("div", _hoisted_9, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.renderSegmentDate(legs)), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.renderSegmentTime(legs)), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("span", _hoisted_10, [
                                  _createVNode(_component_m_flight_timeline_icon)
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Base Refund Charges ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).base_charges), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("span", _hoisted_12, [
                                  _createVNode(_component_m_flight_timeline_icon)
                                ]),
                                _createElementVNode("div", _hoisted_13, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Other Refund Charges ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).other_charges), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("span", _hoisted_14, [
                                  _createVNode(_component_m_flight_timeline_icon)
                                ]),
                                _createElementVNode("div", _hoisted_15, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Base Fare ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).base_fare), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("span", _hoisted_16, [
                                  _createVNode(_component_m_flight_timeline_icon)
                                ]),
                                _createElementVNode("div", _hoisted_17, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Fees ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).fees), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("span", _hoisted_18, [
                                  _createVNode(_component_m_flight_timeline_icon)
                                ]),
                                _createElementVNode("div", _hoisted_19, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Taxes ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).taxes), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("span", _hoisted_20, [
                                  _createVNode(_component_m_flight_timeline_icon)
                                ]),
                                _createElementVNode("div", _hoisted_21, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Others ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).others), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _createElementVNode("span", _hoisted_22, [
                                  _createVNode(_component_m_flight_timeline_icon)
                                ]),
                                _createElementVNode("div", _hoisted_23, [
                                  _createVNode(_component_m_typography, { type: "body" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(" Total Fare ")
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_m_typography, { type: "label" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.getLegsInfo(legs).total_fare), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ])
                ]),
                _: 2
              }, 1024)
            ]))
          }), 128))
        ])
      ]),
      _createVNode(_component_MButton, {
        class: "calculate-btn",
        variant: "filled",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.proceedToNewRefund()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Proceed To New Refund ")
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}