export enum BOOKING_STATUS {
  INITIATED = "INITIATED",
  FAILED = "FAILED",
  ISSUANCE_PROGRESS = "ISSUANCE_PROGRESS",
  ISSUED = "ISSUED",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
  VOIDED = "VOIDED",
  ISSUED_BUT_NOT_PAID = "ISSUED_BUT_NOT_PAID",
  WAITLIST = "WAITLIST",
  REFUNDED = "REFUNDED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
}
