<template>
  <MProgress
    indeterminate
    v-if="isFinancialProfileFetching"
    class="mprogress-alignment"
  />
  <div class="issuance" v-else>
    <section>
      <MTypography type="display"> Add Information </MTypography>
      <MCard>
        <div class="issuance-inputs">
          <MFinancialProfileCombobox
            v-model:inputValue="flightInformation.financialProfile.value"
            label="Financial Profiles"
            itemValue="value"
            itemLabel="label"
            :hasError="flightInformation.financialProfile.hasError"
            :errorMessage="flightInformation.financialProfile.errorMessage"
            :disabled="isPnrFetched || isFetchingPNR"
            :options="financialProfileItems"
          />

          <div class="row">
            <MCombobox
              v-model:inputValue="flightInformation.supplier.value"
              :options="suppliers"
              itemValue="value"
              itemLabel="label"
              label="Airline/Supplier"
              :hasError="flightInformation.supplier.hasError"
              :errorMessage="flightInformation.supplier.errorMessage"
              :disabled="
                isPnrFetched || isFetchingPNR || !isAirlinesAndProvidersFetched
              "
            />

            <MTextfield
              v-model:inputValue.trim="flightInformation.pnrNumber.value"
              label="PNR Number"
              :hasError="flightInformation.pnrNumber.hasError"
              :errorMessage="flightInformation.pnrNumber.errorMessage"
              :disabled="isPnrFetched || isFetchingPNR"
            />
          </div>

          <div class="row">
            <MTextarea
              v-model:inputValue.trim="flightInformation.comments.value"
              label="Comments"
              :hasError="flightInformation.comments.hasError"
              :errorMessage="flightInformation.comments.errorMessage"
              :disabled="isPnrFetched || isFetchingPNR"
            />

            <template v-if="isSabreSelected">
              <MCombobox
                v-model:inputValue="flightInformation.sabreAirline.value"
                :options="sabreAirlinesComboboxOptions"
                itemValue="value"
                itemLabel="label"
                label="Sabre Airline"
                :hasError="flightInformation.sabreAirline.hasError"
                :errorMessage="flightInformation.sabreAirline.errorMessage"
                :disabled="
                  isPnrFetched ||
                  isFetchingPNR ||
                  !isAirlinesAndProvidersFetched
                "
              />
            </template>

            <template v-if="isGalileoSelected">
              <MCombobox
                v-model:inputValue="flightInformation.galileoAirline.value"
                :options="galileoAirlinesComboboxOptions"
                itemValue="value"
                itemLabel="label"
                label="Galileo Airline"
                :hasError="flightInformation.galileoAirline.hasError"
                :errorMessage="flightInformation.galileoAirline.errorMessage"
                :disabled="
                  isPnrFetched ||
                  isFetchingPNR ||
                  !isAirlinesAndProvidersFetched
                "
              />
            </template>
          </div>

          <div class="row" v-show="isSuperUserOrOperationUser">
            <ag-checkbox
              class="override-checkbox"
              label="Override all validations"
              v-model="flightInformation.overrideAllValidations.value"
              :disabled="isPnrFetched || isFetchingPNR"
            />
            <MCombobox
              v-show="flightInformation.overrideAllValidations.value"
              v-model:inputValue="
                flightInformation.selectedApprovalPerson.value
              "
              :hasError="flightInformation.selectedApprovalPerson.hasError"
              :errorMessage="
                flightInformation.selectedApprovalPerson.errorMessage
              "
              :disabled="isPnrFetched || isFetchingPNR"
              label="Approval By"
              itemValue="value"
              itemLabel="label"
              :options="salesUserList"
            />
          </div>
        </div>

        <div class="actions">
          <m-button
            class="issuance-action"
            :disabled="!canFetchPnrDetails || isPnrFetched"
            type="filled"
            @click="canFetchPnrDetails ? handleFetchPnrDetails() : ''"
          >
            {{ isFetchingPNR ? "Fetching PNR" : "Get PNR Details" }}
          </m-button>

          <m-button
            class="issuance-action"
            :disabled="infoHasErrors() || !isPnrFetched"
            type="outlined"
            @click="infoHasErrors() || !isPnrFetched ? '' : handleDataReset()"
          >
            Reset
          </m-button>
        </div>

        <MTypography type="label" v-show="showSabreAirlineInfoMessage">
          {{ sabreAirlineInfoMessage }}
        </MTypography>

        <MTypography type="label" v-show="showGalileoAirlineInfoMessage">
          {{ galileoAirlineInfoMessage }}
        </MTypography>
      </MCard>
    </section>

    <!-- Is Sabre PNR and User is either Operation or Superuser -- START'S HERE -->
    <template v-if="isPnrFetched && isSabreSelected && !isTicketIssued">
      <MCard>
        <template v-if="isPNR_ONHOLD()">
          <IssueTicketPNRExpiry :pnr-details="issueTicketPNRExpiryDetails" />
        </template>
        <MTypography type="display"> Flight Price Details </MTypography>
        <MAccordion
          :initial-open="false"
          v-for="(item, idx) in pnrDetailsV3?.booking_price_details"
          :key="idx"
        >
          <template #title>
            <div class="price-quote-m-accordion-title-checkbox">
              <MCheckbox
                :model-value="isPublicIdSelected(item.public_id)"
                @update:model-value="togglePublicId(item.public_id)"
                :size="18"
                :disabled="item.is_ticketed || !!calculatedFare"
              />
              <div class="price-quote-m-accordion-title-details">
                <div class="flight-itinerary">
                  <div>
                    <span>{{ item.public_id }} - </span
                    >{{ renderPriceQuoteTitle(item.flight_details) }}
                  </div>
                  |
                  <div class="passenger-type-container">
                    <MIcon :name="renderPassengerType(item.passengers).icon" />
                    {{ renderPassengerType(item.passengers).title }} x
                    {{ item.passengers.length }}
                  </div>
                </div>
                <div class="price-container">
                  <MChip
                    class="expiry-countdown"
                    :variant="renderPNRStatusChipColor(item?.pnr_status)"
                    >{{ item?.pnr_status }}</MChip
                  >
                  <div>
                    {{
                      getCurrencyFormatter(
                        item.ag_total_amount?.currency
                      ).format(Number(item.ag_total_amount?.value))
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #content>
            <MCard class="summary-card">
              <!-- Flight Summary -->
              <MTypography type="title"> Flight Summary </MTypography>
              <MFlightSummary
                :pnr-status="item.pnr_status"
                :flightDetails="item.flight_details"
                :travelersCount="
                  travelers(item.passengers)
                    ? travelers(item.passengers).length
                    : 0
                "
              />

              <!-- Flight Travelers -->
              <MTypography type="title"> Travelers </MTypography>
              <MFlightTravelers :travelers="travelers(item.passengers)" />

              <!-- Fare Summary -->
              <MTypography type="title"> Fare Summary </MTypography>
              <PQFareSummary
                :baseFare="getFareHandler('base_fare', item) || ''"
                :grossFare="getFareHandler('gross_fare', item) || ''"
                :hasFareDifference="hasFareDifference(item)"
                :fareDifferenceTitle="
                  renderFareDifferenceTitle(Number(item.ag_fare_difference))
                "
                :fareDifferenceAmount="
                  getFareHandler('ag_fare_difference', item) || ''
                "
                :total-tax="getFareHandler('total_tax', item) || ''"
                :totalAmount="agPricing(item.ag_total_amount)"
                :deal-discount="
                  getFareHandler('ag_deal_promo_discount', item) || ''
                "
                :deal-code="getDiscountCode(item.discount_data)"
              />
            </MCard>
          </template>
        </MAccordion>
        <div class="calculate-fare-btn-container">
          <MButton
            class="calc-price-quote-btn"
            :disabled="disableCalculatePriceQuoteBtn || isCalculatingFare"
            @click="handleCalculateFare"
            type="filled"
            >{{ calculatingBtnText }}</MButton
          >
          <Transition name="field-transition" mode="out-in">
            <MButton
              v-if="calculatedFare"
              :disabled="calculatedFare === null || !isPnrFetched"
              type="outlined"
              @click="!isPnrFetched ? '' : handleResetCalculation()"
            >
              Reset
            </MButton>
          </Transition>
        </div>

        <Transition name="field-transition" mode="out-in">
          <MAccordion v-if="calculatedFare" :initial-open="false">
            <template #title>
              <div class="calculated-price-container">
                <div>Calculated Price Details</div>
                <div>
                  Total Calculated Amount:
                  {{
                    getCurrencyFormatter(
                      calculatedFare?.ag_total_amount?.currency
                    ).format(Number(calculatedFare?.ag_total_amount?.value))
                  }}
                </div>
              </div>
            </template>
            <template #content>
              <div
                class="calculated-accordion-content-container"
                v-for="(item, idx) in calculatedFare.booking_price_details"
                :key="idx"
              >
                <div
                  class="flight-itinerary calculated-title-pax-type-with-count"
                >
                  <div class="passenger-type-container">
                    <MIcon :name="renderPassengerType(item.passengers).icon" />
                    {{ renderPassengerType(item.passengers).title }} x
                    {{ item.passengers.length }}
                  </div>
                </div>

                <MTypography type="title"> Fare Summary </MTypography>
                <PQFareSummary
                  :baseFare="getFareHandler('base_fare', item) || ''"
                  :grossFare="getFareHandler('gross_fare', item) || ''"
                  :hasFareDifference="hasFareDifference(item)"
                  :fareDifferenceTitle="
                    renderFareDifferenceTitle(Number(item.ag_fare_difference))
                  "
                  :fareDifferenceAmount="
                    getFareHandler('ag_fare_difference', item) || ''
                  "
                  :total-tax="getFareHandler('total_tax', item) || ''"
                  :totalAmount="agPricing(item.ag_total_amount)"
                  :deal-discount="
                    getFareHandler('ag_deal_promo_discount', item) || ''
                  "
                  :deal-code="getDiscountCode(item.discount_data)"
                />
              </div>
            </template>
          </MAccordion>
        </Transition>
      </MCard>
    </template>
    <!-- Is Sabre PNR and User is either OperationUser or SuperUser -- END'S HERE -->

    <template
      v-if="isPnrFetched && isSabreSelected === false && !isTicketIssued"
    >
      <section id="flightSummary">
        <MTypography type="display"> Flight Summary </MTypography>

        <template v-if="isPNR_ONHOLD()">
          <IssueTicketPNRExpiry :pnr-details="issueTicketPNRExpiryDetails" />
        </template>
      </section>

      <section>
        <MFlightSummary
          :pnr-status="pnrDetails.pnr_status"
          :flightDetails="flightDetails"
          :travelersCount="travelers ? travelers.length : 0"
        />
      </section>

      <section>
        <MTypography type="display"> Fare Summary </MTypography>

        <MCard>
          <div class="fare-wrapper">
            <div class="fare-details">
              <div class="fare-label">Base Fare</div>
              <div class="fare-amount">
                {{ getFareHandler("base_fare", pnrDetails) }}
              </div>
            </div>

            <div class="fare-details">
              <div class="fare-label">Gross Fare</div>
              <div class="fare-amount">
                {{ getFareHandler("gross_fare", pnrDetails) }}
              </div>
            </div>

            <div class="fare-details" v-if="hasFareDifference(pnrDetails)">
              <div class="fare-label">
                {{
                  renderFareDifferenceTitle(
                    Number(pnrDetails.ag_fare_difference.value)
                  )
                }}
              </div>
              <div class="fare-amount">
                {{ getFareHandler("ag_fare_difference", pnrDetails) }}
              </div>
            </div>

            <Transition name="field-transition" mode="out-in">
              <template
                v-if="
                  !promoApplied &&
                  getFareHandler('ag_deal_promo_discount', pnrDetails)
                "
              >
                <div class="fare-details">
                  <div class="fare-label">
                    <div>Deal Discount</div>
                  </div>
                  <div class="fare-amount">
                    <MChip variant="success" border-less>
                      <b>{{
                        getFareHandler(
                          "ag_deal_promo_discount",
                          pnrDetails,
                          true
                        )
                      }}</b>
                    </MChip>
                  </div>
                </div>
              </template>

              <template v-else-if="promoApplied && !isSuperUserOrOperationUser">
                <div class="fare-details">
                  <div class="fare-label">
                    <div>Promo Discount:</div>
                  </div>
                  <div class="fare-amount">
                    <MChip variant="success" border-less>
                      <b>{{ promoDiscount }}</b>
                    </MChip>
                  </div>
                </div>
              </template>
            </Transition>

            <div class="fare-details">
              <div class="fare-label">Total Amount</div>
              <div class="fare-amount fare-total">
                {{
                  promoApplied
                    ? discountedPrice
                    : agPricing(pnrDetails?.ag_total_amount)
                }}
              </div>
            </div>

            <Transition name="field-transition" mode="out-in">
              <template
                v-if="
                  !promoApplied &&
                  getFareHandler('ag_deal_promo_discount', pnrDetails)
                "
              >
                <div class="fare-details">
                  <div class="fare-label fi-inline-label">
                    <div>Dealcode Applied</div>
                    <MChip variant="success" border-less>
                      {{ pnrDetails.discount_data.discount_code }}
                    </MChip>
                  </div>
                </div>
              </template>

              <template v-else-if="promoApplied && !isSuperUserOrOperationUser">
                <div class="fare-details">
                  <div class="fare-label fi-inline-label">
                    <div>Promocode Applied:</div>
                    <MChip variant="success" border-less>
                      {{ appliedPromoCode }}
                    </MChip>
                    <MFabButton
                      class="fi-promo-delete-action"
                      icon="m-delete"
                      @click="handleRemovePromoCode"
                    />
                  </div>
                </div>
              </template>
            </Transition>

            <template v-if="!isSuperUserOrOperationUser">
              <div class="fare-details">
                <div class="fare-label fi-inline-label fi-label-start">
                  <MTextfield
                    dense
                    v-model:inputValue.trim="promoCode"
                    placeholder="Enter promo code"
                    :has-error="!!hasPromoErrorMessage"
                    :error-message="hasPromoErrorMessage"
                    class="fi-inline-input"
                  />
                  <MButton
                    dense
                    :disabled="disableApplyPromoBtn || promoCode.length < 1"
                    @click="handleApplyPromoCode"
                  >
                    Apply
                  </MButton>
                </div>
              </div>
            </template>
          </div>
        </MCard>
      </section>

      <section>
        <MTypography type="display"> Travelers </MTypography>
        <MFlightTravelers :travelers="travelers($store.getters.airTravelers)" />
      </section>
    </template>

    <section v-if="showIssueTicketBox()">
      <MTypography type="display"> Issue Ticket </MTypography>

      <m-card class="issuance-note">
        <MTypography :type="renderNoteType"> Note: </MTypography>
        <MTypography :type="renderNoteType" class="issuance-note-heading">
          Final fare displayed here, excluding any additional ancillary charges
          except those already included.
        </MTypography>
      </m-card>

      <MCard>
        <div class="m-issue-ticket">
          <MTextfield
            class="m-otp"
            v-model:inputValue="OTP.value"
            label="OTP"
            :disabled="isIssueTicketLoading"
            :hasError="OTP.hasError"
            :errorMessage="OTP.errorMessage"
            @change="handleFlightIssuanceErrors()"
          />

          <m-button
            class="m-issue-ticket-action"
            :disabled="!canIssueFlightTicket"
            type="filled"
            @click="canIssueFlightTicket ? handleFlightTicketIssuance() : ''"
            :is-loading="isIssueTicketLoading"
          >
            Issue Ticket
          </m-button>
        </div>
      </MCard>
    </section>

    <template v-if="isPnrFetched && isTicketIssued">
      <section>
        <MTypography type="display"> Issuance Status </MTypography>
        <MCard class="m-success-message"> Ticket Issued Successfully </MCard>
      </section>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  MTypography,
  MCard,
  MTextfield,
  MTextarea,
  MCombobox,
  MButton,
  MFinancialProfileCombobox,
  MFabButton,
  MChip,
  MAccordion,
  MCheckbox,
  MIcon,
} from "@aeroglobe/ag-core-ui";

import MFlightTravelers from "@/components/MFlightTravelers.vue";

import { IFinancialProfile } from "@/ag-portal-common/interfaces/financialProfile.interface";
import { SUPPLIER_NAMES } from "@/ag-flight-components/enums/supplier_names";
import { fetchPNRValidation } from "@/ag-flight-components/validations/pnrFetchValidation";
import {
  PNRDetails,
  PassengersList,
  ISSUE_TICKET_PAYLOAD,
  SalesUser,
  SABRE_AIRLINE,
  FLIGHT_PROVIDER,
  GALILEO_AIRLINE,
  PNRDetailsV3,
  CalculatedFareV3,
  FlightDetails,
  BookingPriceDetails,
  PRICE_QUOTE_FARE_CALCULATION_PAYLOAD,
  ExtendedPrice,
  DealDiscount,
} from "@/ag-flight-components/types";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";
import {
  IFlightDetails,
  PassengerTraveler,
} from "@/modules/FlightIssuance/dtos/flightIssuance.dto";
import { ValidationError } from "yup";
import {
  getFormattedDateTime,
  yupValidationErrorAsSchema,
} from "@/ag-portal-common/utils/helpers";
import { issueTicketValidation } from "@/ag-flight-components/validations/issueTicketValidation";
import { FLIGHT_ISSUANCE_ANALYTICS_EVENTS } from "@/modules/FlightIssuance/constants/anaylticsEvents";
import analyticsService from "@/analytic.service";
import { USER_ROLES } from "@/ag-portal-common/enums/USER_ROLES";
import { ORGANIZATION_STATUSES } from "@/ag-portal-common/enums/ORGANIZATION_STATUSES";
import { FPComboboxOptions } from "@aeroglobe/ag-core-ui/dist/src/components/material/molecules/molecules.type";
import {
  ChipVariant,
  ComboboxOptions,
} from "@aeroglobe/ag-core-ui/dist/src/components/material/atoms/atoms.type";
import UTILS from "@/ag-portal-common/utils";
import { FinancialProfile, User } from "@/modules/Auth/types";
import { AUTH_GETTERS } from "@/modules/Auth/vuex/getters";
import { parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { FORMAT_DD_MMM_YYYY_WITH_DASH } from "@/ag-portal-common/constants/dateTimeFormats";
import MFlightSummary from "@/components/MFlightSummary.vue";
import {
  getPassengerTypeIcon,
  getTravelerTypeName,
} from "@/ag-flight-components/constants/TravelerTypes";
import { IconName } from "@aeroglobe/ag-core-ui/dist/src/components/material/icons/icons.type";
import PQFareSummary from "../components/FareSummary.vue";
import IssueTicketPNRExpiry from "../components/IssueTicketPNRExpiry.vue";
import { PNR_STATUSES } from "@/ag-flight-components/enums/flight_issuance";
import { PNR_STATUSES_COLOR_MAPPER } from "../constants";

export default defineComponent({
  name: "FlightIssuance",
  components: {
    MTypography,
    MCard,
    MTextfield,
    MTextarea,
    MCombobox,
    MButton,
    MFlightSummary,
    MFlightTravelers,
    MFinancialProfileCombobox,
    MChip,
    MFabButton,
    MAccordion,
    MCheckbox,
    MIcon,
    PQFareSummary,
    IssueTicketPNRExpiry,
  },
  async mounted() {
    if (!this.isSuperUserOrOperationUser && this.financialProfiles) {
      let defaultFinancialProfile = this.financialProfiles.find(
        (item) => item.is_default
      );

      if (defaultFinancialProfile) {
        this.flightInformation.financialProfile.value =
          defaultFinancialProfile.public_id;
      }

      this.handleFlightIssuanceErrors();
    } else {
      this.$store.dispatch("fetchSalesUsersListForApproval");
    }
  },
  beforeMount() {
    this.handleDataReset();
  },
  async created() {
    if (this.isSuperUserOrOperationUser) {
      await this.$store.dispatch("fetchFinancialProfiles", { is_linked: true });
    }

    await this.handleFetchPnrDetailsByRoute(!this.isSuperUserOrOperationUser);
  },
  unmounted() {
    this.handleDataReset();

    this.isAirlinesAndProvidersFetched = false;
  },
  watch: {
    pnrNumber(value: string) {
      this.flightInformation.pnrNumber.value = value;
    },
    "flightInformation.pnrNumber.value"(value: string) {
      this.$store.commit("setPnr", value);
    },
    "flightInformation.overrideAllValidations.value"(value: boolean) {
      if (value === false) {
        this.flightInformation.selectedApprovalPerson.value = "";
      }
    },
    async "flightInformation.financialProfile.value"(value: string) {
      this.isAirlinesAndProvidersFetched = false;

      let id = value;

      if (value.length > 0) {
        if (this.isSuperUserOrOperationUser) {
          const financialProfiles = this.$store.getters
            .issuanceFinancialProfiles as IFinancialProfile[];
          const financialProfile = financialProfiles.find(
            (profile) => profile.public_id === value
          );

          if (financialProfile) {
            id = financialProfile.organization_public_id ?? "";
          }
        }

        await this.fetchAirlinesAndProviders(id);

        await this.handleFetchPnrDetailsByRoute(false);
      }
    },
  },
  computed: {
    isFinancialProfileFetching(): boolean {
      return this.$store.getters.isFetchingFinancialProfiles;
    },
    user(): User | null {
      return this.$store.getters[AUTH_GETTERS.USER];
    },
    financialProfiles(): FinancialProfile[] | null {
      return this.$store.getters[AUTH_GETTERS.FINANCIAL_PROFILES];
    },

    sabreAirlines(): SABRE_AIRLINE[] {
      return this.$store.getters.sabreAirlines as SABRE_AIRLINE[];
    },
    galileoAirlines(): GALILEO_AIRLINE[] {
      return this.$store.getters.galileoAirline as GALILEO_AIRLINE[];
    },
    flightProviders(): FLIGHT_PROVIDER[] {
      return this.$store.getters.flightProviders as FLIGHT_PROVIDER[];
    },

    sabreAirlinesComboboxOptions(): ComboboxOptions[] {
      return [...this.sabreAirlines]
        .sort((a: SABRE_AIRLINE, b: SABRE_AIRLINE) =>
          a.airline_code.localeCompare(b.airline_code)
        )
        .map((airline: SABRE_AIRLINE) => ({
          label: airline.airline_code,
          value: airline.airline_code,
        }));
    },
    galileoAirlinesComboboxOptions(): ComboboxOptions[] {
      return [...this.galileoAirlines]
        .sort((a: GALILEO_AIRLINE, b: GALILEO_AIRLINE) =>
          a.airline_code.localeCompare(b.airline_code)
        )
        .map((airline: GALILEO_AIRLINE) => ({
          label: airline.airline_code,
          value: airline.airline_code,
        }));
    },

    canFetchPnrDetails(): boolean {
      return !(this.infoHasErrors() || this.$store.getters.isFetchingPNR);
    },
    canIssueFlightTicket(): boolean {
      return !(
        this.OTP.hasError ||
        this.isIssueTicketLoading ||
        this.infoHasErrors()
      );
    },

    pnrNumber(): string {
      return this.$store.getters.pnr;
    },

    financialProfileItems(): FPComboboxOptions[] {
      let financialProfiles;
      if (this.isSuperUserOrOperationUser) {
        financialProfiles = this.$store.getters.issuanceFinancialProfiles;
      } else {
        if (this.financialProfiles) {
          financialProfiles = this.financialProfiles;
        }
      }

      if (financialProfiles) {
        return financialProfiles?.map((fp: IFinancialProfile) => {
          const planType = fp?.plan_name?.split(" ")[1]?.toLowerCase();
          const sector = fp?.sector?.replace(/^Aeroglobe\s*-\s*/, "");
          return {
            id: fp?.platform_id,
            label: fp?.financial_profile_name,
            value: fp?.public_id,
            isActive: fp?.status === ORGANIZATION_STATUSES.ACTIVE,
            status: fp?.status,
            sector: sector,
            type: planType,
          };
        });
      } else {
        return [];
      }
    },

    suppliers(): ComboboxOptions[] {
      return [...this.flightProviders]
        .filter(
          (provider: FLIGHT_PROVIDER) => provider.is_ticket_issuance_enabled
        )
        .sort((a: FLIGHT_PROVIDER, b: FLIGHT_PROVIDER) =>
          a.name.localeCompare(b.name)
        )
        .map((provider: FLIGHT_PROVIDER) => ({
          label: UTILS.toTitleCase(provider.name),
          value: provider.name,
        }));
    },

    isSabreSelected(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.SABRE
      );
    },
    isGalileoSelected(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.GALILEO
      );
    },
    showSabreAirlineInfoMessage(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.SABRE
      );
    },
    showGalileoAirlineInfoMessage(): boolean {
      return (
        this.flightInformation.supplier.value?.toLowerCase() ===
        SUPPLIER_NAMES.GALILEO
      );
    },
    sabreAirlineInfoMessage(): string {
      if (!this.isSabreSelected) return "";

      const sabreAirline = this.findSabreAirlineByAirlineCode(
        this.flightInformation.sabreAirline.value
      ) as SABRE_AIRLINE | null;

      if (!sabreAirline) return "";

      if (sabreAirline.pcc) {
        return `Please queue on "${sabreAirline.pcc}" for ${
          sabreAirline.airline_code
        }. ${
          sabreAirline.airline_code === "PK"
            ? "Only PK international routes can be issued via sabre"
            : ""
        }`;
      }

      return "This airline cannot be issued through portal, please issue through whatsapp or email.";
    },
    galileoAirlineInfoMessage(): string {
      if (!this.isGalileoSelected) return "";

      const galileoAirline = this.findGalileoAirlineByAirlineCode(
        this.flightInformation.galileoAirline.value
      ) as GALILEO_AIRLINE | null;

      if (!galileoAirline) return "";

      if (galileoAirline.pcc) {
        return `Please queue on "${galileoAirline.pcc}" for ${
          galileoAirline.airline_code
        }. ${
          galileoAirline.airline_code === "PK"
            ? "Only PK international routes can be issued via galileo"
            : ""
        }`;
      }

      return "This airline cannot be issued through portal, please issue through whatsapp or email.";
    },

    isFetchingPNR(): boolean {
      return this.$store.getters.isFetchingPNR;
    },
    isPnrFetched: {
      get(): boolean {
        if (this.$store.getters.isFetchingPNR) return false;
        if (this.isSabreSelected) {
          return this.pnrDetailsV3 !== null;
        } else {
          return this.pnrDetails !== null;
        }
      },
      set() {
        this.$store.commit("resetPNRDetails");
      },
    },
    pnrDetails(): PNRDetails {
      return this.$store.getters.pnrDetails;
    },
    pnrDetailsV3(): PNRDetailsV3 {
      return this.$store.getters.pnrDetailsV3;
    },
    flightDetails(): IFlightDetails[] {
      if (this.isPnrFetched) {
        const flights = this.pnrDetails.flight_details as IFlightDetails[];
        return flights;
      } else {
        return [];
      }
    },
    isIssueTicketLoading(): boolean {
      return this.$store.getters.isIssueTicketLoading;
    },
    isTicketed(): boolean {
      let isTicketed;
      if (this.isPnrFetched) {
        if (this.isSabreSelected) {
          isTicketed = this.pnrDetailsV3?.booking_price_details?.every(
            (x) => x.is_ticketed
          );
        } else {
          isTicketed = this.$store.getters.pnrDetails?.is_ticketed;
        }
        return isTicketed;
      }
      return false;
    },
    isTicketIssued(): boolean {
      return this.$store.getters?.isTicketIssued;
    },
    isSuperUserOrOperationUser(): boolean {
      return UTILS.isUser(USER_ROLES.AG_SUPER_USER, USER_ROLES.OPERATIONS);
    },
    salesUserList() {
      const salesUsers: SalesUser[] = this.$store.getters.salesUsersList || [];
      return (
        salesUsers.map((item) => {
          return {
            label: item.email,
            value: item.email,
          };
        }) || []
      );
    },
    disableApplyPromoBtn(): boolean {
      return this.$store.getters.isApplyFlightPromoLoading;
    },
    promoApplied(): boolean {
      return this.$store.getters.promoCodeResponse?.applicable || false;
    },
    orignalPrice() {
      const orignal_price =
        this.$store.getters.promoCodeResponse?.orignal_price || {};
      return orignal_price
        ? getCurrencyFormatter(orignal_price?.currency).format(
            orignal_price?.value
          )
        : "N/A";
    },
    discountedPrice() {
      const discounted_price =
        this.$store.getters.promoCodeResponse?.discounted_price || {};
      return discounted_price
        ? getCurrencyFormatter(discounted_price?.currency).format(
            discounted_price?.value
          )
        : "N/A";
    },
    promoDiscount() {
      const discount_amount =
        this.$store.getters.promoCodeResponse?.discount_amount || {};
      return discount_amount?.value
        ? getCurrencyFormatter(discount_amount?.currency).format(
            -Number(discount_amount?.value)
          )
        : "";
    },
    hasPromoErrorMessage(): string {
      const isSucceed =
        this.$store.getters.promoCodeResponse?.applicable || false;
      if (!isSucceed) {
        return this.$store.getters.promoCodeResponse?.message;
      }
      return "";
    },
    disableCalculatePriceQuoteBtn(): boolean {
      return this.selectedPublicIds.length < 1;
    },
    isCalculatingFare(): boolean {
      return this.$store.getters.isCalculatingFare;
    },
    calculatedFare(): CalculatedFareV3 {
      return this.$store.getters.calculatedFare;
    },
    issueTicketPNRExpiryDetails(): PNRDetails | PNRDetailsV3 {
      if (this.isSabreSelected) {
        return this.pnrDetailsV3;
      }
      return this.pnrDetails;
    },
    calculatingBtnText(): string {
      return this.isCalculatingFare
        ? "Calculating Price Quote"
        : "Calculate Price Quote";
    },
    renderNoteType(): string {
      const isMobileView = window.innerWidth <= 768;
      return isMobileView ? "label" : "body";
    },
  },
  data() {
    return {
      options: [
        {
          code: "PK",
          country: "Pakistan",
        },
        {
          code: "AG",
          country: "Afghanistan",
        },
      ],
      flightInformation: {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        supplier: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pnrNumber: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        comments: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        sabreAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        galileoAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        overrideAllValidations: {
          value: false,
          hasError: false,
          errorMessage: "",
        },
        selectedApprovalPerson: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      },

      OTP: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      validateFlightInfoErrors: false,
      validateFlightIssuanceErrors: false,

      isAirlinesAndProvidersFetched: false,
      promoCode: "",
      promoError: "",

      appliedPromoCode: "",
      selectedPublicIds: [] as Array<string>,
    };
  },
  methods: {
    getFormattedDateTime,
    getCurrencyFormatter,
    getDiscountCode(discountDetails: DealDiscount | null) {
      return discountDetails ? discountDetails.discount_code : "";
    },
    getFareHandler(
      fareType:
        | "base_fare"
        | "gross_fare"
        | "ag_fare_difference"
        | "ag_deal_promo_discount"
        | "total_amount"
        | "total_tax",
      details: PNRDetails | BookingPriceDetails,
      isNegative = false
    ): string | null {
      if (!this.isPnrFetched) {
        return null;
      }
      const fare = details[fareType];
      return fare
        ? getCurrencyFormatter(fare.currency).format(
            isNegative ? -Number(fare.value) : Number(fare.value)
          )
        : null;
    },
    findSabreAirlineByAirlineCode(
      airline_code: string
    ): SABRE_AIRLINE | undefined {
      return this.sabreAirlines.find(
        (airline) => airline.airline_code === airline_code
      );
    },
    findGalileoAirlineByAirlineCode(
      airline_code: string
    ): GALILEO_AIRLINE | undefined {
      return this.galileoAirlines.find(
        (airline) => airline.airline_code === airline_code
      );
    },
    transformPnrData() {
      const { pnrNumber, comments, sabreAirline, galileoAirline, supplier } =
        this.flightInformation;

      const flightProvider = this.flightProviders.find((provider) => {
        return provider.name.toLowerCase() === supplier.value.toLowerCase();
      });

      const sabreAirlineCode = this.sabreAirlines.find((airline) => {
        return airline.airline_code === sabreAirline.value;
      });

      const galileoAirlineCode = this.galileoAirlines.find((airline) => {
        return airline.airline_code === galileoAirline.value;
      });

      const mappedData = {
        financial_profile_public_id:
          this.flightInformation.financialProfile.value,
        pnr: pnrNumber.value.trim(),
        comments: comments.value.trim(),
        airline: flightProvider ? flightProvider.value : null,
        ...(this.isSabreSelected && {
          airline_code: sabreAirlineCode ? sabreAirlineCode.airline_code : null,
        }),
        ...(this.isGalileoSelected && {
          airline_code: galileoAirlineCode
            ? galileoAirlineCode.airline_code
            : null,
        }),
        ...(this.isSuperUserOrOperationUser && {
          approval_by: this.flightInformation.selectedApprovalPerson.value,
          override_all_validations:
            this.flightInformation.overrideAllValidations.value,
        }),
      };
      return mappedData;
    },

    infoIsEmpty() {
      const transformedArray = Object.keys(this.flightInformation).map(
        (key) => (this.flightInformation as any)[key]
      );

      return transformedArray.every((item) => item.value === "");
    },
    infoHasErrors() {
      const transformedArray = Object.keys(this.flightInformation).map(
        (key) => (this.flightInformation as any)[key]
      );

      this.handleFlightInfoErrors();

      return transformedArray.some((item) => item.hasError);
    },

    handleFlightInfoErrors() {
      if (this.validateFlightInfoErrors) {
        const {
          financialProfile,
          supplier,
          pnrNumber,
          sabreAirline,
          galileoAirline,
          overrideAllValidations,
          selectedApprovalPerson,
          comments,
        } = this.flightInformation;

        if (financialProfile.value === "") {
          financialProfile.hasError = true;
          financialProfile.errorMessage = "Financial Profile is required";
        } else {
          financialProfile.hasError = false;
          financialProfile.errorMessage = "";
        }

        if (supplier.value === "") {
          supplier.hasError = true;
          supplier.errorMessage = "Supplier is required";
        } else {
          supplier.hasError = false;
          supplier.errorMessage = "";
        }

        if (pnrNumber.value === "") {
          pnrNumber.hasError = true;
          pnrNumber.errorMessage = "PNR is required";
        } else {
          pnrNumber.hasError = false;
          pnrNumber.errorMessage = "";
        }

        if (this.isSabreSelected) {
          if (sabreAirline.value === "") {
            sabreAirline.hasError = true;
            sabreAirline.errorMessage = "Sabre Airline is required";
          } else {
            sabreAirline.hasError = false;
            sabreAirline.errorMessage = "";
          }
        }

        if (this.isGalileoSelected) {
          if (galileoAirline.value === "") {
            galileoAirline.hasError = true;
            galileoAirline.errorMessage = "Galileo Airline is required";
          } else {
            galileoAirline.hasError = false;
            galileoAirline.errorMessage = "";
          }
        }

        if (this.isSuperUserOrOperationUser) {
          if (comments.value === "") {
            comments.hasError = true;
            comments.errorMessage = "Comments are required";
          } else {
            comments.hasError = false;
            comments.errorMessage = "";
          }
        }

        if (this.isSuperUserOrOperationUser) {
          if (
            overrideAllValidations.value === true &&
            selectedApprovalPerson.value === ""
          ) {
            selectedApprovalPerson.hasError = true;
            selectedApprovalPerson.errorMessage =
              "Approval person email is required";
          } else {
            selectedApprovalPerson.hasError = false;
            selectedApprovalPerson.errorMessage = "";
          }
        }
      }
    },
    handleFlightIssuanceErrors() {
      this.OTP.value = this.OTP.value.replace(/\s+/g, "");
      if (this.validateFlightIssuanceErrors) {
        if (this.OTP.value === "") {
          this.OTP.hasError = true;
          this.OTP.errorMessage = "OTP is required";
        } else {
          this.OTP.hasError = false;
          this.OTP.errorMessage = "";
        }
      }
    },
    handleResetCalculation() {
      this.selectedPublicIds = [];
      this.$store.commit("resetCalculatedFare");
    },
    handleDataReset() {
      this.flightInformation = {
        financialProfile: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        supplier: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        pnrNumber: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        comments: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        sabreAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        galileoAirline: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        overrideAllValidations: {
          value: false,
          hasError: false,
          errorMessage: "",
        },
        selectedApprovalPerson: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      };

      this.OTP = {
        value: "",
        hasError: false,
        errorMessage: "",
      };

      this.promoCode = "";
      this.promoError = "";

      this.selectedPublicIds = [];

      this.isPnrFetched = false;
      this.validateFlightInfoErrors = false;
      this.validateFlightIssuanceErrors = false;

      if (this.isSuperUserOrOperationUser) {
        this.isAirlinesAndProvidersFetched = false;
        this.$store.commit("resetActiveAirlinesAndProviders");
      }

      this.$store.commit("resetPNRDetails");

      this.$store.commit("removeFlightPromoResponse");
      this.handleFlightIssuanceErrors();
    },

    formatString<T extends number | string>(value: T | undefined): string {
      return value ? String(value) : typeof value === "number" ? "0" : "";
    },

    // FETCH PNR BY ROUTE
    async handleFetchPnrDetailsByRoute(canFetchPNRDetails = true) {
      const QUERY = this.$route.query;
      const PNR = QUERY.pnr as string | null;
      const SUPPLIER = QUERY.supplier as string | null;
      const SABRE_AIRLINE = QUERY.sabre_airline as string | null;
      const GALILEO_AIRLINE = QUERY.galileo_airline as string | null;

      if (!(PNR && SUPPLIER)) {
        return;
      }

      this.$store.commit("setPnr", PNR);
      this.flightInformation.pnrNumber.value = PNR;

      if (SUPPLIER.toLowerCase() === SUPPLIER_NAMES.SABRE) {
        this.flightInformation.sabreAirline.value = SABRE_AIRLINE as string;
      }

      if (SUPPLIER.toLowerCase() === SUPPLIER_NAMES.GALILEO) {
        this.flightInformation.galileoAirline.value = GALILEO_AIRLINE as string;
      }

      const provider = this.flightProviders.find(
        (provider) => provider.name.toLowerCase() === SUPPLIER.toLowerCase()
      );

      this.flightInformation.supplier.value = provider ? provider.name : "";

      if (canFetchPNRDetails) {
        setTimeout(async () => {
          await this.handleFetchPnrDetails();
        }, 300);
      }
    },

    // FETCH PNR
    async handleFetchPnrDetails() {
      this.validateFlightInfoErrors = true;

      this.handleFlightInfoErrors();

      if (this.infoHasErrors()) {
        return;
      }

      try {
        const payload = this.transformPnrData();

        const request = await fetchPNRValidation.validate(payload, {
          abortEarly: false,
        });

        if (this.isSabreSelected) {
          await this.$store.dispatch("fetchPNRV3", request);
        } else {
          await this.$store.dispatch("fetchPNR", request);
        }

        analyticsService.logActionEvent(
          FLIGHT_ISSUANCE_ANALYTICS_EVENTS.FETCH_PNR_DETAILS,
          payload
        );

        this.onScrollToSummaryHandler();
      } catch (ex) {
        if (ex instanceof ValidationError) {
          yupValidationErrorAsSchema(ex);
        }
      } finally {
        this.handleFlightInfoErrors();
      }
    },

    // ISSUE TICKET
    async handleFlightTicketIssuance() {
      this.validateFlightIssuanceErrors = true;

      this.handleFlightIssuanceErrors();

      if (this.OTP.hasError) {
        return;
      }

      try {
        const userEmail = this.user ? this.user.email : "";
        const promo_id = this.$store.getters.promoCodeResponse?.promo_id;
        let reference;

        if (this.isSabreSelected) {
          reference = this.pnrDetailsV3?.reference;
        } else {
          reference = this.pnrDetails?.reference;
        }

        const payload: ISSUE_TICKET_PAYLOAD = {
          pnr: this.$store.getters.pnr.toUpperCase(),
          reference: reference,
          financial_profile_public_id:
            this.flightInformation.financialProfile.value,
          emails: [userEmail],
          otp: this.OTP.value,
          ...(this.isSabreSelected && {
            fare_public_ids: this.selectedPublicIds,
          }),
          ...(this.promoApplied &&
            promo_id && {
              promo_id,
            }),
        };

        await issueTicketValidation.validate(
          { otp: this.OTP.value },
          { abortEarly: false }
        );

        await this.$store.dispatch("issueTicket", payload);
        this.$store.commit("removeFlightPromoResponse");
        analyticsService.logActionEvent(
          FLIGHT_ISSUANCE_ANALYTICS_EVENTS.FLIGHT_TICKET_ISSUANCE,
          payload
        );
      } catch (ex) {
        if (ex instanceof ValidationError) {
          yupValidationErrorAsSchema(ex);
        }
      } finally {
        this.handleFlightIssuanceErrors();
      }
    },

    async fetchAirlinesAndProviders(id: string) {
      const payload = {
        id,
        isFinancialProfile: !this.isSuperUserOrOperationUser,
      };

      await this.$store.dispatch("fetchAirlinesAndProviders", payload);

      this.isAirlinesAndProvidersFetched = true;
    },
    handleRemovePromoCode() {
      this.promoCode = "";
      this.$store.commit("removeFlightPromoResponse");
    },
    async handleApplyPromoCode() {
      const payload = {
        promo_code: this.promoCode,
        reference: this.pnrDetails.reference,
      };
      analyticsService.logActionEvent(
        FLIGHT_ISSUANCE_ANALYTICS_EVENTS.APPLY_FLIGHT_PROMO_CODE,
        payload
      );
      await this.$store.dispatch("applyFlightPromoCode", payload);
      this.appliedPromoCode = this.promoCode;
    },

    onScrollToSummaryHandler() {
      const flightSummary = document.getElementById("flightSummary");

      if (flightSummary) {
        flightSummary.scrollIntoView({ behavior: "smooth" });
      }
    },
    travelers(airtravelers: Array<PassengersList>): PassengerTraveler[] {
      const travelers = airtravelers.map((traveler: PassengersList) => {
        const title = traveler.title;
        const personName = traveler.person_name;
        const surname = traveler.surname;
        const passengerTypeCode = this.formatString(
          traveler?.passenger_type_code
        );
        const birthdate = traveler?.birthdate
          ? new Date(traveler.birthdate)
          : "-";
        const formattedBirthdate =
          birthdate != "-"
            ? this.getFormattedDateTime(birthdate, FORMAT_DD_MMM_YYYY_WITH_DASH)
            : "-";
        const baseFare =
          traveler?.pricing_detail?.fare.value === null
            ? "-"
            : traveler?.pricing_detail?.fare.currency +
              " " +
              this.formatString(traveler?.pricing_detail?.fare.value);
        const surcharges =
          traveler?.pricing_detail?.surcharges.value === null
            ? "-"
            : traveler?.pricing_detail?.surcharges.currency +
              " " +
              this.formatString(traveler?.pricing_detail?.surcharges.value);
        const taxes =
          traveler?.pricing_detail?.total_tax.value === null
            ? "-"
            : traveler?.pricing_detail?.total_tax.currency +
              " " +
              this.formatString(traveler?.pricing_detail?.total_tax.value);
        const totalFare =
          traveler?.pricing_detail?.total_fare.value === null
            ? "-"
            : traveler?.pricing_detail?.total_fare.currency +
              " " +
              this.formatString(traveler?.pricing_detail?.total_fare.value);
        const fees =
          traveler?.pricing_detail?.fees.value === null
            ? "-"
            : traveler?.pricing_detail?.fees.currency +
              " " +
              this.formatString(traveler?.pricing_detail?.fees.value);

        return {
          name: `${title} ${personName}`,
          surname: surname,
          type: passengerTypeCode,
          birthdate: formattedBirthdate,
          base_fare: `${baseFare}`,
          surcharges: `${surcharges}`,
          taxes: `${taxes}`,
          total_fare: `${totalFare}`,
          fees: `${fees}`,
        };
      });
      return travelers;
    },
    isPublicIdSelected(publicId: string) {
      return this.selectedPublicIds.includes(publicId);
    },
    togglePublicId(publicId: string) {
      const index = this.selectedPublicIds.indexOf(publicId);
      if (index === -1) {
        this.selectedPublicIds.push(publicId);
      } else {
        this.selectedPublicIds.splice(index, 1);
      }
    },
    renderPriceQuoteTitle(flightDetails: FlightDetails[]): string {
      return flightDetails
        .map((x, idx) => {
          if (idx === 0) {
            return `${x.departure_short_code} ✈ ${x.arrival_short_code}`;
          }
          const lastItem = flightDetails[idx - 1];
          if (lastItem.arrival_short_code === x.departure_short_code) {
            return `${x.arrival_short_code}`;
          } else {
            return `${x.departure_short_code} ✈ ${x.arrival_short_code}`;
          }
        })
        .join(" ✈ ");
    },
    renderPassengerType(passengers: PassengersList[]): {
      title: string;
      icon: IconName;
    } {
      const passengerType = passengers[0]?.passenger_type_code as string;
      const title = getTravelerTypeName(passengerType) as string;

      return {
        title,
        icon: getPassengerTypeIcon(passengerType) as IconName,
      };
    },
    renderFareDifferenceTitle(ag_fare_difference: number): string {
      return ag_fare_difference > 0 ? "Fees" : "Discount";
    },
    hasFareDifference(details: PNRDetails | BookingPriceDetails): boolean {
      return !!(details.ag_fare_difference && details.ag_fare_difference.value);
    },
    agPricing(ag_total_amount: ExtendedPrice): string {
      if (this.isPnrFetched) {
        const agPrice = ag_total_amount?.value;
        return agPrice
          ? getCurrencyFormatter(ag_total_amount?.currency).format(agPrice)
          : "N/A";
      } else {
        return "";
      }
    },
    handleCalculateFare() {
      const payload: PRICE_QUOTE_FARE_CALCULATION_PAYLOAD = {
        reference: this.pnrDetailsV3.reference,
        financial_profile_public_id:
          this.flightInformation.financialProfile.value,
        fare_public_ids: this.selectedPublicIds,
      };
      this.$store.dispatch("calculatePQFare", payload);
    },
    isPNR_ONHOLD(): boolean {
      let status;
      if (this.isSabreSelected) {
        status = this.pnrDetailsV3?.booking_price_details?.every((x) =>
          x?.pnr_status.toUpperCase()
        );
      } else {
        status = this.pnrDetails?.pnr_status?.toUpperCase();
      }
      return status === "ONHOLD";
    },
    renderPNRStatusChipColor(pnr_status: PNR_STATUSES): ChipVariant {
      return PNR_STATUSES_COLOR_MAPPER[pnr_status] as ChipVariant;
    },
    showIssueTicketBox(): boolean {
      if (!this.isPnrFetched || this.isTicketIssued) {
        return false;
      }
      const hasCalculatedFare = !!this.calculatedFare;

      if (this.isSabreSelected && this.isSuperUserOrOperationUser) {
        // For Sabre and authorized users, show if not ticketed or has calculated fare
        return !this.isTicketed && hasCalculatedFare;
      } else {
        // For non-Sabre or unauthorized users, show only if not ticketed
        return !this.isTicketed;
      }
    },
  },
});
</script>

<style lang="css">
.issuance .m-table {
  min-width: 0;
}

.is-pq-fare-checkbox-disabled {
  background-color: lightgray;
  border-color: lightgray;
}
</style>

<style lang="css" scoped>
.issuance {
  --text-color: #49454f;
  --label-color: #636363;
  --border-color: #ababab;
  --error-color: #b3261e;
  --theme-color: var(--green-color);

  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 50px;
}

.issuance .fi-expiry-cards {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
}
.issuance .fi-expiry-cards .fi-expiry-card {
  flex: 1;
}
.issuance .fi-expiry-cards .fi-card-title {
  font-weight: 600;
  color: var(--theme-color);
}
.issuance .fare-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.issuance .fare-wrapper .fare-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--m-secondary-color);
}
.issuance .fare-wrapper .fare-details .fare-label {
  font-weight: 500;
}
.issuance .fare-wrapper .fare-details .fare-total {
  font-size: 20px;
  font-weight: 600;
}
.issuance .fare-wrapper .fare-details .fi-inline-label {
  min-height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.issuance .fare-wrapper .fare-details .fi-inline-label.fi-label-start {
  align-items: flex-start;
}
.issuance .fare-wrapper .fare-details .fi-inline-input {
  width: 250px;
  margin-bottom: 0;
}
.issuance .fare-wrapper .fare-details .fi-promo-delete-action {
  color: var(--m-error-color);
  background-color: color-mix(
    in srgb,
    var(--m-error-color) 25%,
    transparent 75%
  );
  transform: scale(0.7);
  width: 32px;
  height: 32px;
}

.issuance-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.issuance-inputs,
.issuance-action {
  margin-bottom: 16px;
}

.issuance-inputs .row {
  display: flex;
  gap: 24px;
  width: 100%;
}

.issuance .actions {
  display: flex;
  gap: 16px;
}

.m-issue-ticket {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.m-issue-ticket .m-otp {
  max-width: 400px;
}

.m-success-message {
  color: #22bb33;
}

.override-checkbox {
  min-width: 230px;
}

.field-transition-enter-active,
.field-transition-leave-active {
  transition: opacity 0.5s;
}
.field-transition-enter,
.field-transition-leave-to {
  opacity: 0;
}

.price-quote-m-accordion-title-checkbox {
  display: flex;
  gap: 10px;
}
.calc-price-quote-btn {
  margin: 15px 0px;
}
.calculated-price-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  font-weight: 600;
}
.calculate-fare-btn-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media screen and (max-width: 786px) {
  .fi-expiry-cards {
    flex-direction: column;
  }

  .issuance-inputs .row {
    display: flex;
    flex-direction: column;
  }

  .m-issue-ticket .m-otp {
    max-width: none;
  }
}

@media screen and (max-width: 600px) {
  .issuance-inputs .row {
    display: flex;
    flex-direction: column;
  }

  .m-issue-ticket .m-otp {
    max-width: none;
  }
}

.issuance-note {
  display: flex;
  gap: 10px;
  top: 0;
  margin-bottom: 10px;
  z-index: 2;
  background-color: #fdeded;
  padding: 16px;
}

.issuance-note-heading {
  color: #da4c4c;
}
</style>

<style lang="scss" scoped>
.price-quote-m-accordion-title-checkbox {
  width: 100%;
}
.price-quote-m-accordion-title-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  width: 100%;
  padding: 0px 20px;
  .price-container {
    display: flex;
    gap: 8px;
    align-items: center;
    font-weight: 600;
    .ticketed-label {
      background: var(--green-color);
      color: var(--white-color);
      padding: 5px;
      border-radius: 6px;
    }
  }
}
.flight-itinerary {
  display: flex;
  gap: 15px;
  .passenger-type-container {
    display: flex;
    gap: 10px;
  }
}

.calculated-title-pax-type-with-count {
  font-weight: 600;
  margin: 20px 0;
}

.calculated-accordion-content-container {
  padding: 20px;
}
.summary-card {
  padding: 0px;
}
</style>
