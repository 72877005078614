import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb78b8f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-container" }
const _hoisted_2 = { class: "grid-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_DetailsTile = _resolveComponent("DetailsTile")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, { "initial-open": true }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        title: "General Details",
        class: "section-title",
        mPrepandIcon: "m-info"
      })
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DetailsTile, {
            subHeading: "PNR",
            heading: _ctx.renderPNR,
            icon: "m-number"
          }, null, 8, ["heading"]),
          _createVNode(_component_DetailsTile, {
            subHeading: "Issuance ID",
            heading: _ctx.renderIssuanceId,
            icon: "m-number"
          }, null, 8, ["heading"]),
          _createVNode(_component_DetailsTile, {
            subHeading: "Status",
            heading: _ctx.renderBookingStatus,
            icon: "m-status"
          }, null, 8, ["heading"]),
          _createVNode(_component_DetailsTile, {
            subHeading: "Booking Type",
            heading: _ctx.renderBookingType,
            icon: "m-complete-application"
          }, null, 8, ["heading"]),
          _createVNode(_component_DetailsTile, {
            subHeading: "Total Passengers",
            heading: _ctx.renderPassengerCount,
            icon: "m-profile"
          }, null, 8, ["heading"]),
          _createVNode(_component_DetailsTile, {
            subHeading: "Supplier",
            heading: _ctx.renderSupplier,
            icon: "m-flight"
          }, null, 8, ["heading"]),
          _createVNode(_component_DetailsTile, {
            subHeading: "Amount",
            heading: _ctx.renderIssuedAtAmount,
            icon: "m-request-quote"
          }, null, 8, ["heading"]),
          _createVNode(_component_DetailsTile, {
            subHeading: "Deal Discount",
            heading: _ctx.renderDealDiscountAmount,
            icon: "m-deals-and-promos"
          }, null, 8, ["heading"])
        ])
      ])
    ]),
    _: 1
  }))
}