import { MutationTree } from "vuex";
import {
  IMyIssuanceState,
  IssuanceDetails,
} from "@/ag-portal-common/interfaces/myIssuance.interface";

const mutations: MutationTree<IMyIssuanceState> = {
  setMyIssuanceListLoading(state, isLoading) {
    state.isMyFlightIssuanceLoading = isLoading;
  },
  setMyIssuanceList(state, issuanceList) {
    state.myFlightIssuanceList = issuanceList;
  },
  resetMyIssuanceList(state: IMyIssuanceState) {
    state.myFlightIssuanceList = {
      count: 0,
      previous: "",
      next: "",
      bookings: [],
    };
  },
  setMyIssuanceDetailsLoading(state, isLoading) {
    state.isMyIssuanceDetailsLoading = isLoading;
  },
  setMyIssuanceDetails(state, issuanceDetails: IssuanceDetails) {
    state.myFlightIssuanceDetails = issuanceDetails;
  },
  resetIssuanceDetails(state) {
    state.myFlightIssuanceDetails = null;
  },
};

export default mutations;
