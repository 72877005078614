import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MProgress = _resolveComponent("MProgress")!
  const _component_IssuanceBasicDetails = _resolveComponent("IssuanceBasicDetails")!
  const _component_IssuanceFlightDetails = _resolveComponent("IssuanceFlightDetails")!
  const _component_IssuancePassengerDetails = _resolveComponent("IssuancePassengerDetails")!
  const _component_IssuancePassengerWiseFareDetails = _resolveComponent("IssuancePassengerWiseFareDetails")!

  return (_ctx.isMyIssuanceDetailsLoading)
    ? (_openBlock(), _createBlock(_component_MProgress, {
        key: 0,
        indeterminate: "",
        class: "mprogress-alignment"
      }))
    : (_ctx.myFlightIssuanceDetails)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_IssuanceBasicDetails),
          _createVNode(_component_IssuanceFlightDetails),
          _createVNode(_component_IssuancePassengerDetails),
          _createVNode(_component_IssuancePassengerWiseFareDetails)
        ]))
      : _createCommentVNode("", true)
}