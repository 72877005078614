import { BOOKING_STATUS } from "@/ag-portal-common/enums/BOOKING_STATUS";

export const MY_BOOKINGS_API_PATH = {
  GET_BOOKINGS: "v4/flights/bookings/",
  DOWNLOAD_BRAND_TICKET:
    "/download-ticket/{0}/?render_fare=false&is_edited=false&per_adult_edited_fare=0&per_child_edited_fare=0&per_infant_edited_fare=0",
  SEND_AIRLINE_TICKET: "/download-airline-ticket",
  BOOKING_LOGS: "/v2/booking/logs",
  ISSUEANCE_LOGS: "/flights/issuance/logs/",
  DOWNLOAD_VOUCHER: "v5/hotels/bookings/get-voucher",
  DOWNLOAD_TOUR_VOUCHER: "v1/tours/bookings/get-voucher/{0}",
};

export const BOOKING_STATUSES_MAPPING = {
  [BOOKING_STATUS.CANCELED.toUpperCase()]: "error",
  [BOOKING_STATUS.CONFIRMED.toUpperCase()]: "info",
  [BOOKING_STATUS.FAILED.toUpperCase()]: "error",
  [BOOKING_STATUS.INITIATED.toUpperCase()]: "default",
  [BOOKING_STATUS.ISSUANCE_PROGRESS.toUpperCase()]: "warning",
  [BOOKING_STATUS.ISSUED.toUpperCase()]: "success",
  [BOOKING_STATUS.ISSUED_BUT_NOT_PAID.toUpperCase()]: "warning",
  [BOOKING_STATUS.VOIDED.toUpperCase()]: "success",
  [BOOKING_STATUS.WAITLIST.toUpperCase()]: "info",
  [BOOKING_STATUS.REFUNDED.toUpperCase()]: "error",
  [BOOKING_STATUS.PARTIALLY_REFUNDED.toUpperCase()]: "info",
};

export const BOOKING_STATUSES_OPTIONS = [
  { value: BOOKING_STATUS.CANCELED, label: "Canceled" },
  { value: BOOKING_STATUS.CONFIRMED, label: "Confirmed" },
  { value: BOOKING_STATUS.FAILED, label: "Failed" },
  { value: BOOKING_STATUS.INITIATED, label: "Initiated" },
  { value: BOOKING_STATUS.ISSUANCE_PROGRESS, label: "Issuance in progress" },
  { value: BOOKING_STATUS.ISSUED, label: "Issued" },
  { value: BOOKING_STATUS.ISSUED_BUT_NOT_PAID, label: "Issued but not paid" },
  { value: BOOKING_STATUS.VOIDED, label: "Voided" },
  { value: BOOKING_STATUS.WAITLIST, label: "Waitlist" },
  { value: BOOKING_STATUS.REFUNDED, label: "Refunded" },
  { value: BOOKING_STATUS.PARTIALLY_REFUNDED, label: "Partially Refunded" },
];
