<template>
  <MAccordion :initial-open="true">
    <template v-slot:title>
      <AgIconInfoBar
        test-id=""
        title="General Details"
        class="section-title"
        mPrepandIcon="m-info"
      />
    </template>
    <template v-slot:content>
      <div class="content-container">
        <div class="grid-container">
          <DetailsTile subHeading="PNR" :heading="renderPNR" icon="m-number">
          </DetailsTile>

          <DetailsTile
            subHeading="Issuance ID"
            :heading="renderIssuanceId"
            icon="m-number"
          >
          </DetailsTile>

          <DetailsTile
            subHeading="Status"
            :heading="renderBookingStatus"
            icon="m-status"
          >
          </DetailsTile>

          <DetailsTile
            subHeading="Booking Type"
            :heading="renderBookingType"
            icon="m-complete-application"
          >
          </DetailsTile>

          <DetailsTile
            subHeading="Total Passengers"
            :heading="renderPassengerCount"
            icon="m-profile"
          >
          </DetailsTile>

          <DetailsTile
            subHeading="Supplier"
            :heading="renderSupplier"
            icon="m-flight"
          >
          </DetailsTile>
          <DetailsTile
            subHeading="Amount"
            :heading="renderIssuedAtAmount"
            icon="m-request-quote"
          >
          </DetailsTile>
          <DetailsTile
            subHeading="Deal Discount"
            :heading="renderDealDiscountAmount"
            icon="m-deals-and-promos"
          >
          </DetailsTile>
        </div>
      </div>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MAccordion } from "@aeroglobe/ag-core-ui";
import DetailsTile from "@/components/OrganizationDetailTile.vue";
import {
  FlightDetails,
  IssuanceDetails,
} from "@/ag-portal-common/interfaces/myIssuance.interface";
import { getCurrencyFormatter } from "@/ag-flight-components/utils";

export default defineComponent({
  name: "IssuanceBasicDetails",
  components: {
    DetailsTile,
    MAccordion,
  },
  methods: {
    getFormattedValue(value: string | number): string {
      return value ? value.toString().replace(/_/g, " ") : "-";
    },
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
  },
  computed: {
    flightDetails(): FlightDetails {
      const myFlightIssuanceDetails = this.myFlightIssuanceDetails;
      return myFlightIssuanceDetails.flight_details;
    },
    myFlightIssuanceDetails(): IssuanceDetails {
      return this.$store.getters.myFlightIssuanceDetails;
    },
    renderIssuanceId(): string {
      const issuanceId = this.$route.params?.id as string;
      return this.getFormattedValue(issuanceId);
    },
    renderBookingStatus(): string {
      const details = this.myFlightIssuanceDetails;
      return this.getFormattedValue(details?.status);
    },
    renderPNR(): string {
      return this.getFormattedValue(this.flightDetails.pnr);
    },
    renderPassengerCount(): string {
      const details = this.flightDetails;
      return this.getFormattedValue(details?.passengers?.length || 0);
    },
    renderBookingType(): string {
      const details = this.myFlightIssuanceDetails;
      return this.getFormattedValue(details?.booking_type);
    },
    renderSupplier(): string {
      const details = this.flightDetails;
      return details.supplier.toUpperCase();
    },
    renderIssuedAtAmount(): string {
      const details = this.flightDetails;
      const price = details?.price?.ag_total_amount;
      if (price) {
        const amount = this.formattedCurrency(price.currency, price.value);
        return this.getFormattedValue(amount);
      }
      return "-";
    },
    renderDealDiscountAmount(): string {
      const details = this.flightDetails;
      const price = details?.price?.deal_discount;
      if (price) {
        const amount = this.formattedCurrency(price.currency, price.value);
        return this.getFormattedValue(amount);
      }
      return "-";
    },
  },
});
</script>

<style scoped lang="css">
.content-container {
  padding: 0;
}

.tile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1350px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 963px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
