import { IMyIssuanceState } from "@/ag-portal-common/interfaces/myIssuance.interface";

const state: IMyIssuanceState = {
  isMyFlightIssuanceLoading: false,
  myFlightIssuanceList: {
    count: 0,
    previous: "",
    next: "",
    bookings: [],
  },
  isMyIssuanceDetailsLoading: false,
  myFlightIssuanceDetails: null,
};

export default state;
