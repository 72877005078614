import { RouteRecordRaw } from "vue-router";
import { ROLES_AND_PERMISSION_ON_ROUTES } from "@/ag-portal-common/configs/rolesAndPermissionsMappingOnRoutes";
import MyIssuanceList from "@/modules/MyIssuances/views/MyIssuanceList.vue";
import MyIssuanceDetailPage from "@/modules/MyIssuances/views/MyIssuanceDetailPage.vue";
import { PATH } from "@/ag-portal-common/constants/path";

const routes: Array<RouteRecordRaw> = [
  {
    path: PATH.MY_ISSUANCE,
    name: "myIssuances",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.MY_ISSUANCES.ROLES,
      permissions: ROLES_AND_PERMISSION_ON_ROUTES.MY_ISSUANCES.PERMISSIONS,
    },
    component: MyIssuanceList,
  },
  {
    path: PATH.FLIGHTS_ISSUANCE_DETAILS,
    name: "myIssuancesDetailsPage",
    meta: {
      roles: ROLES_AND_PERMISSION_ON_ROUTES.MY_ISSUANCES_DETAILS.ROLES,
      permissions:
        ROLES_AND_PERMISSION_ON_ROUTES.MY_ISSUANCES_DETAILS.PERMISSIONS,
    },
    component: MyIssuanceDetailPage,
  },
];

export default routes;
