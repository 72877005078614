<template>
  <MAccordion :initial-open="true">
    <template v-slot:title>
      <AgIconInfoBar
        test-id=""
        title="Passenger Details"
        class="section-title"
        mPrepandIcon="m-passenger-management"
      />
    </template>
    <template v-slot:content>
      <MDataTable
        :headers="tableHeaders"
        :data="issuedPassengers"
        :item-per-page="30"
        :is-api-paginated="false"
        :has-search="false"
        :has-pagination="false"
      >
        <template #last_name="{ item }">
          <MTypography type="label">
            {{ getFormattedValue(item.last_name) }}</MTypography
          >
        </template>
        <template #first_name="{ item }">
          <MTypography type="label">
            {{ getFormattedValue(item.first_name) }}</MTypography
          >
        </template>
        <template #ticket_number="{ item }">
          <MTypography type="label">
            {{ getFormattedValue(item.ticket_number) }}</MTypography
          >
        </template>
        <template #invoice_number="{ item }">
          <MTypography type="label">
            {{ getFormattedValue(item.invoice_number) }}</MTypography
          >
        </template>
        <template #passenger_type="{ item }">
          <MTypography type="label">
            {{ getFormattedValue(item.passenger_type) }}</MTypography
          >
        </template>
      </MDataTable>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MAccordion, MTypography } from "@aeroglobe/ag-core-ui";
import {
  FlightDetails,
  IssuanceDetails,
  Passenger,
} from "@/ag-portal-common/interfaces/myIssuance.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";

export default defineComponent({
  name: "IssuancePassengerDetails",
  components: {
    MAccordion,
  },
  methods: {
    getFormattedValue(value: string | number): string {
      return value ? value.toString().replace(/_/g, " ") : "-";
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          title: "Title",
          value: "title",
          key: "title",
          sortable: true,
        },
        {
          title: "First Name",
          value: "first_name",
          key: "first_name",
          sortable: true,
        },
        {
          title: "Last Name",
          value: "last_name",
          key: "last_name",
          sortable: true,
        },
        {
          title: "Passenger Type",
          value: "passenger_type",
          key: "passenger_type",
          sortable: true,
        },
        {
          title: "Invoice Number",
          value: "invoice_number",
          key: "invoice_number",
          sortable: true,
        },
        {
          title: "Ticket Number",
          value: "ticket_number",
          key: "ticket_number",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    flightDetails(): FlightDetails {
      const myFlightIssuanceDetails = this.myFlightIssuanceDetails;
      return myFlightIssuanceDetails.flight_details;
    },
    myFlightIssuanceDetails(): IssuanceDetails {
      return this.$store.getters.myFlightIssuanceDetails;
    },
    issuedPassengers(): Passenger[] | string[] {
      const details = this.flightDetails;
      return details.passengers || [];
    },
  },
});
</script>

<style scoped lang="css">
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
