import {
  Airline,
  BaggageInfo,
  ExtendedPrice,
} from "@/ag-flight-components/types";
import { IMyBookingBase } from "@/interfaces/myBookingBase.interface";
import { PASSENGER_TYPE } from "../enums/PASSENGER_TYPES";

export enum ISSUANCE_STATUS {
  UNDEFINED = "UNDEFINED",
  FETCHED_SUCCESS = "FETCHED_SUCCESS",
  FETCHED_FAILURE = "FETCHED_FAILURE",
  ISSUANCE_SUCCESS = "ISSUANCE_SUCCESS",
  ISSUANCE_FAILURE = "ISSUANCE_FAILURE",
  PRE_ISSUANCE_FAILURE = "PRE_ISSUANCE_FAILURE",
  POST_ISSUANCE_FAILURE = "POST_ISSUANCE_FAILURE",
}

export enum ISSUANCE_BOOKING_TYPE {
  PORTAL_BOOKING = "PORTAL_BOOKING",
  NON_PORTAL_BOOKING = "NON_PORTAL_BOOKING",
}

export interface IssuanceDetails {
  issuance_id: string;
  status: ISSUANCE_STATUS;
  booking_type: ISSUANCE_BOOKING_TYPE;
  flight_details: FlightDetails;
  agent: AgentDetails;
  issuance_time: Date | null;
}

export interface Passenger {
  title: string;
  first_name: string;
  last_name: string;
  passenger_type: PASSENGER_TYPE;
  total_fare: number;
  ag_fare: number;
  invoice_number: string | null;
  ticket_number: string;
  tax: Tax | null;
}

export interface Tax {
  name: string;
  value: ExtendedPrice;
}

export interface FlightSegment {
  origin: string;
  destination: string;
  departure_date: Date;
  arrival_date: Date;
  flight_number: string;
  rbd: string | null;
  baggage_info: BaggageInfo | null;
  airline: Airline | null;
}

export interface AgentDetails {
  email: string;
  platform_id: string;
  financial_profile_name: string;
  organization_id: string;
  organization_name: string;
}

export interface FlightDetails {
  supplier: string;
  pnr: string;
  flight_sector: string;
  total_amount: ExtendedPrice;
  ag_total_amount: ExtendedPrice;
  passengers: string[] | Passenger[];
  flight_segments?: FlightSegment;
  price?: Price;
}

export interface PaxTypeFare {
  base_fare: ExtendedPrice;
  tax: ExtendedPrice;
  gross_fare: ExtendedPrice;
  ag_total_amount: ExtendedPrice | null;
  deal_discount?: ExtendedPrice | null;
  ag_price_difference?: ExtendedPrice | null;
  total_fare: ExtendedPrice;
}

export type PaxTypePriceBreakdown = {
  adult: PaxTypeFare;
  child: PaxTypeFare;
  infant: PaxTypeFare;
};

export interface Price {
  ag_total_amount: ExtendedPrice;
  total_amount: ExtendedPrice;
  pax_type_price_breakdown: PaxTypePriceBreakdown[];
  deal_discount?: ExtendedPrice | null;
}

export interface IMyFlightIssuance extends IMyBookingBase<IssuanceDetails> {
  bookings: IssuanceDetails[];
}

export interface IMyIssuanceState {
  isMyFlightIssuanceLoading: boolean;
  myFlightIssuanceList: IMyFlightIssuance | null;
  isMyIssuanceDetailsLoading: boolean;
  myFlightIssuanceDetails: IssuanceDetails | null;
}
