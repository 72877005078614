<template>
  <MAccordion :initial-open="true">
    <template v-slot:title>
      <AgIconInfoBar
        test-id=""
        title="Passenger Wise Fare Details"
        class="section-title"
        mPrepandIcon="m-payment-management"
      />
    </template>
    <template v-slot:content>
      <MDataTable
        :headers="tableHeaders"
        :data="amountBreakDown"
        :item-per-page="3"
        :is-api-paginated="false"
        :has-search="false"
        :has-pagination="false"
      >
        <template #base_fare="{ item }">
          <MTypography type="label">{{
            formattedCurrency(item.base_fare.currency, item.base_fare.value)
          }}</MTypography>
        </template>
        <template #total_fare="{ item }">
          <MTypography type="label">{{
            formattedCurrency(item.total_fare.currency, item.total_fare.value)
          }}</MTypography>
        </template>
        <template #ag_fare="{ item }">
          <MTypography type="label">{{
            formattedCurrency(item.ag_fare.currency, item.ag_fare.value)
          }}</MTypography>
        </template>
        <template #deal_discount="{ item }">
          <MTypography type="label" v-if="item.deal_discount">{{
            formattedCurrency(
              item.deal_discount.currency,
              item.deal_discount.value
            )
          }}</MTypography>
          <MTypography type="label" v-else> - </MTypography>
        </template>
      </MDataTable>
    </template>
  </MAccordion>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MAccordion, MTypography } from "@aeroglobe/ag-core-ui";
import {
  FlightDetails,
  IssuanceDetails,
  PaxTypeFare,
} from "@/ag-portal-common/interfaces/myIssuance.interface";
import { MDataTableHeader } from "@/ag-portal-common/types";
import { getCurrencyFormatter } from "@/ag-portal-common/utils/helpers";

export default defineComponent({
  name: "IssuancePassengerWiseFareDetails",
  components: {
    MAccordion,
  },
  methods: {
    getFormattedValue(value: string | number): string {
      return value ? value.toString().replace(/_/g, " ") : "-";
    },
    formattedCurrency(currency: string, amount: number): string {
      return getCurrencyFormatter(currency).format(amount);
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          title: "Passenger Type",
          value: "passenger_type",
          key: "passenger_type",
          sortable: true,
        },
        {
          title: "Base Fare",
          value: "base_fare",
          key: "base_fare",
          sortable: true,
        },
        {
          title: "Total Fare",
          value: "total_fare",
          key: "total_fare",
          sortable: true,
        },
        {
          title: "Ag Fare",
          value: "ag_fare",
          key: "ag_fare",
          sortable: true,
        },
        {
          title: "Total Tax",
          value: "total_tax",
          key: "total_tax",
          sortable: true,
        },
        {
          title: "Deal Discount",
          value: "deal_discount",
          key: "deal_discount",
          sortable: true,
        },
      ] as MDataTableHeader[],
    };
  },
  computed: {
    flightDetails(): FlightDetails {
      const myFlightIssuanceDetails = this.myFlightIssuanceDetails;
      return myFlightIssuanceDetails.flight_details;
    },
    myFlightIssuanceDetails(): IssuanceDetails {
      return this.$store.getters.myFlightIssuanceDetails;
    },
    amountBreakDown(): PaxTypeFare[] | [] {
      const details = this.flightDetails?.price?.pax_type_price_breakdown;
      if (details && details[0]) {
        let breakdown = [];

        if (details[0]?.adult) {
          const amount = details[0].adult;
          breakdown.push({
            ...amount,
            passenger_type: "Adult",
            total_tax: this.formattedCurrency(
              amount.total_fare.currency,
              amount.total_fare.value - amount.base_fare.value
            ),
          });
        }

        if (details[0]?.child) {
          const amount = details[0].child;
          breakdown.push({
            ...amount,
            passenger_type: "Child",
            total_tax: this.formattedCurrency(
              amount.total_fare.currency,
              amount.total_fare.value - amount.base_fare.value
            ),
          });
        }

        if (details[0]?.infant) {
          const amount = details[0].infant;
          breakdown.push({
            ...amount,
            passenger_type: "Infant",
            total_tax: this.formattedCurrency(
              amount.total_fare.currency,
              amount.total_fare.value - amount.base_fare.value
            ),
          });
        }

        return breakdown || [];
      }
      return [];
    },
  },
});
</script>

<style scoped lang="css">
.section-title {
  margin: 0;
  border: none;
  padding: 0;
  align-items: center;
}
</style>
