import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, { "initial-open": true }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        title: "Passenger Details",
        class: "section-title",
        mPrepandIcon: "m-passenger-management"
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_MDataTable, {
        headers: _ctx.tableHeaders,
        data: _ctx.issuedPassengers,
        "item-per-page": 30,
        "is-api-paginated": false,
        "has-search": false,
        "has-pagination": false
      }, {
        last_name: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedValue(item.last_name)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        first_name: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedValue(item.first_name)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        ticket_number: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedValue(item.ticket_number)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        invoice_number: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedValue(item.invoice_number)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        passenger_type: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedValue(item.passenger_type)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["headers", "data"])
    ]),
    _: 1
  }))
}