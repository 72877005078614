import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AgIconInfoBar = _resolveComponent("AgIconInfoBar")!
  const _component_MTypography = _resolveComponent("MTypography")!
  const _component_MDataTable = _resolveComponent("MDataTable")!
  const _component_MAccordion = _resolveComponent("MAccordion")!

  return (_openBlock(), _createBlock(_component_MAccordion, { "initial-open": true }, {
    title: _withCtx(() => [
      _createVNode(_component_AgIconInfoBar, {
        "test-id": "",
        title: "Flight Details",
        class: "section-title",
        mPrepandIcon: "m-flight"
      })
    ]),
    content: _withCtx(() => [
      _createVNode(_component_MDataTable, {
        headers: _ctx.tableHeaders,
        data: _ctx.flightSegment,
        "item-per-page": 30,
        "is-api-paginated": false,
        "has-search": false,
        "has-pagination": false
      }, {
        baggage_info: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getBaggageInfo(item.baggage_info)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        rbd: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.rbd ?? "-"), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        departure_date: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "body"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                item.departure_date,
                _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
              )), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_MTypography, {
            class: "description",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.departure_date, _ctx.FORMAT_HH_SS_24_HOURS)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        arrival_date: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "body"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(
                item.arrival_date,
                _ctx.FORMAT_DD_MMM_YYYY_WITH_DASH
              )), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_MTypography, {
            class: "description",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getFormattedDateTime(item.arrival_date, _ctx.FORMAT_HH_SS_24_HOURS)), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        airline: _withCtx(({ item }) => [
          _createVNode(_component_MTypography, {
            class: "description td-full-width",
            type: "label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getAirlineInfo(item.airline).name), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["headers", "data"])
    ]),
    _: 1
  }))
}